import { BehaviorSubject } from 'rxjs';

export class PocHelper {
  private static loadingSubject      = new BehaviorSubject<boolean>(false);
  private static loadingCount        = 0;
  private static lstAllOptionsData   = new BehaviorSubject<any>(null);
  private static salesDateSubject    = new BehaviorSubject<any>(null);
  private static dateInJobClearCache = new BehaviorSubject<string>('');

  // Loading
  static setLoading(loading: boolean) {
    return this.loadingSubject.next(loading);
  }

  static isLoading() {
    return this.loadingSubject.asObservable();
  }

  static startLoading() {
    this.loadingCount++;
    this.loadingSubject.next(true);
  }

  static stopLoading() {
    this.loadingCount--;

    if (this.loadingCount === 0) {
      this.loadingSubject.next(false);
    }
  }

  // Filters data
  static getLstAllOptionsData() {
    return this.lstAllOptionsData.asObservable();
  }

  // Salesdate data
  static setSalesDate(value: string[]) {
    if (this.salesDateSubject.value) {
      return;
    }

    return this.salesDateSubject.next(value);
  }

  static getSalesDate() {
    return this.salesDateSubject.asObservable();
  }

  // DateInJob data
  static setDateInJobClearCache(value: string) {
    return this.dateInJobClearCache.next(value);
  }

  static getDateInJobClearCache() {
      return this.dateInJobClearCache.asObservable();
  }

  // Compare dates
  static compareDates(date1: string, date2: string) {
    const fullDate1 = `${date1}-01`;

    const d1 = new Date(fullDate1);
    const d2 = new Date(date2);

    return d1 <= d2;
}

  static getCategories() {
    return [
      {
        label: 'Event',
        value: 'EVENT'
      },
      {
        label: 'End',
        value: 'END'
      },
      {
        label: 'Checkout',
        value: 'CHECKOUT'
      },
      {
        label: 'Deli',
        value: 'DELI'
      },
      {
        label: 'Liquor',
        value: 'LIQUOR'
      },
      {
        label: 'Bakery',
        value: 'BAKERY'
      },
      {
        label: 'Snack',
        value: 'SNACK'
      },
      {
        label: 'Food Section',
        value: 'FOOD_SECTION',
      },
      {
        label: 'Store front',
        value: 'STORE_FRONT',
      },
      {
        label: 'Total',
        value: 'ALL_SALES_LOCATION'
      }
    ]
  }

  static getAccountCategories() {
    const categories = ['ALL', 'COLA_SSD', 'OTHER_SSD', 'COFFEE', 'JUICE', 'NS_TEA', 'BLACK_TEA', 'SPORTS', 'ENERGY', 'WATER'];
    const classNames = ['all_category', 'cola_ssd', 'other_ssd', 'coffee', 'juice', 'ns_tea', 'black_tea', 'sports', 'energy', 'water'];
    const properties = ['Event', 'End', 'Checkout', 'Deli', 'Liquor', 'Bakery', 'Snack', 'Food Section', 'Store front', 'Total'];
    const totalProperties = ['TM', 'LY'];

    const results = [];

    categories.forEach((category, index) => {
      const className = classNames[index];

      properties.forEach(property => {
          if (property === 'Total') {
              totalProperties.forEach(totalProperty => {
                results.push({
                    label: 'Total',
                    value: `${category}_${totalProperty.replace(/ /g, '_').toUpperCase()}`,
                    className: className
                });
              });
          } else {
              results.push({
                  label: property,
                  value: `${category}_${property.replace(/ /g, '_').toUpperCase()}`,
                  className: className
              });
          }
      });
    });

    return results;
  }
}
