import { Component, Renderer2, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {AnalyticsHelper} from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import lodashClonedeep from 'lodash.clonedeep';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
@Component({
  selector: 'intage_score_cards',
  templateUrl: './score_cards.component.html',
  styleUrls: ['./score_cards.component.scss']
})
export class ScoreCardsComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  categories: any;
  otcChannels: any;
  categoryColors: any;
  categoriesFilter: any;
  marketShareData: any;
  makerLogo: any;
  scoreCardData: any;
  marketShareMakers: any;
  summaryRegions: any;
  summaryPackageTypes: any;
  shareVsLySelectedPackageType: any;
  scoreCardSelectedMaker: any;
  scoreCardSelectedChannel: any;
  scoreCardSelectedCategory: any;
  scoreCardSelectedRegion: any;
  scoreCardSelectedPackageType: any;
  populated: boolean;
  aggregateBy: any;
  chartId = "score-card"

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges() {
    window.scrollTo(0,0);
      if(!this.populated){
        this.shareVsLySelectedPackageType = ['All'];
        this.scoreCardSelectedMaker = ['All'];
        this.scoreCardSelectedChannel = ['OTC Total'];
        this.scoreCardSelectedCategory = ['Total'];
        this.scoreCardSelectedRegion = ['CCBJI Territory'];
        this.scoreCardSelectedPackageType = ['All'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.makerLogo = IntageHelper.MakerLogos();
        this.scoreCardData = {
          vsLy: '',
          vsLyVolume: ''
        };
        this.marketShareMakers = [{maker:'All'}];
        this.makerLogo.forEach(x => {
          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.categoriesFilter = IntageHelper.Categories();
        this.otcChannels = IntageHelper.OTCChannels();
        this.summaryPackageTypes = IntageHelper.PackageType();
        this.summaryRegions = IntageHelper.Regions();
        this.handleMarketShare();      
      }    
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["scoreCardSelectedMaker"] = [...this.scoreCardSelectedMaker];
      window["scoreCardSelectedChannel"] = [...this.scoreCardSelectedChannel];
      window["scoreCardSelectedCategory"] = [...this.scoreCardSelectedCategory];
      window["scoreCardSelectedRegion"] = [...this.scoreCardSelectedRegion];
      window["scoreCardSelectedPackageType"] = [...this.scoreCardSelectedPackageType];
      window["scoreCardSelectedPackageType"] = [...this.scoreCardSelectedPackageType];
      window["shareVsLySelectedPackageType"] = [...this.shareVsLySelectedPackageType];
    } 
    else 
    {
      if (window["scoreCardSelectedMaker"] != null) { 
        this.scoreCardSelectedMaker = window["scoreCardSelectedMaker"]; 
      }
      if (window["scoreCardSelectedChannel"] != null) { this.scoreCardSelectedChannel = window["scoreCardSelectedChannel"]; }
      if (window["scoreCardSelectedCategory"] != null) { this.scoreCardSelectedCategory = window["scoreCardSelectedCategory"]; }
      if (window["scoreCardSelectedRegion"] != null) { this.scoreCardSelectedRegion = window["scoreCardSelectedRegion"]; }
      if (window["scoreCardSelectedPackageType"] != null) { this.scoreCardSelectedPackageType = window["scoreCardSelectedPackageType"]; }
      if (window["scoreCardSelectedPackageType"] != null) { this.scoreCardSelectedPackageType = window["scoreCardSelectedPackageType"]; }
      if (window["shareVsLySelectedPackageType"] != null) { this.shareVsLySelectedPackageType = window["shareVsLySelectedPackageType"]; }
    }
  }

  onRemoveFilterMakers($event) {
    if(this.scoreCardSelectedMaker.length == 0){
      this.scoreCardSelectedMaker = ['All'];
      this.SaveOptionValueTemp(false);
      this.onScoreCardMakerChange()
    }
    if ($event.value === 'All') {
      this.scoreCardSelectedMaker = [];
      return;
    }
  }
  onAddFilterMakers($event) {
    if ($event === 'All') {
      this.scoreCardSelectedMaker = ['All'];
      return;
    }
    if(this.scoreCardSelectedMaker.filter(x=>x!='All').length==(this.marketShareMakers.length-1)){
      this.scoreCardSelectedMaker = ['All'];
    }else{
      this.scoreCardSelectedMaker = this.scoreCardSelectedMaker.filter(e => e !== 'All');;
    }
  } 
  onRemoveFilterScoreChannel($event) {
    if(this.scoreCardSelectedChannel.length == 0){
      this.scoreCardSelectedChannel = ['OTC Total'];
      this.SaveOptionValueTemp(false);
      this.onScoreCardChannelChange()
    }
    if ($event.value === 'OTC Total') {
      this.scoreCardSelectedChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event.value);
      this.scoreCardSelectedChannel = this.scoreCardSelectedChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.scoreCardSelectedChannel = this.scoreCardSelectedChannel.filter(e => e !== parent);
    }
  }
  onAddFilterScoreChannel($event) {
    if ($event === 'OTC Total') {
      this.scoreCardSelectedChannel = ['OTC Total'];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event);
      this.scoreCardSelectedChannel = this.scoreCardSelectedChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChildIntage(parent);
      arrChilds.forEach(child => {
        if (this.otcChannels.some(x=>x.channel==child) && !this.scoreCardSelectedChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.scoreCardSelectedChannel = this.scoreCardSelectedChannel.filter(e => !arrChilds.includes(e));
        this.scoreCardSelectedChannel = arrTemp.concat(this.scoreCardSelectedChannel);
      }else{
        this.scoreCardSelectedChannel = this.scoreCardSelectedChannel.filter(e => e !== parent);
      }
    }
    var checkParentFilter = true;
    var filterNotCheck = this.otcChannels.filter(e => !this.scoreCardSelectedChannel.includes(e.channel) && e.channel!='OTC Total');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.channel) || !this.scoreCardSelectedChannel.includes(AnalyticsHelper.getSmddParent(element.channel))) {
        checkParentFilter=false;
      }
    });
    if(checkParentFilter){
      this.scoreCardSelectedChannel = ['OTC Total'];
    }else{
      this.scoreCardSelectedChannel = this.scoreCardSelectedChannel.filter(e => e !== 'OTC Total');
    }
  } 
  onRemoveFilterScoreCategory($event) {
    if(this.scoreCardSelectedCategory.length == 0){
      this.scoreCardSelectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onScoreCardCategoryChange()
    }
    if ($event.value === 'Total') {
      this.scoreCardSelectedCategory = [];
      return;
    }
  }
  onAddFilterScoreCategory($event) {
    if ($event === 'Total') {
      this.scoreCardSelectedCategory = ['Total'];
      return;
    }
    if(this.scoreCardSelectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.scoreCardSelectedCategory = ['Total'];
    }else{
      this.scoreCardSelectedCategory = this.scoreCardSelectedCategory.filter(e => e !== 'Total');;
    }
  }
  onRemoveFilterScoreRegion($event) {
    if(this.scoreCardSelectedRegion.length == 0){
      this.scoreCardSelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
      this.onScoreCardRegionChange()
    }
    if ($event.value === 'CCBJI Territory') {
      this.scoreCardSelectedRegion = [];
      return;
    }
  }
  onAddFilterScoreRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.scoreCardSelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.scoreCardSelectedRegion.filter(x=> x != 'CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.scoreCardSelectedRegion = ['CCBJI Territory'];
    }else{
      this.scoreCardSelectedRegion = this.scoreCardSelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  }  
  onRemoveFilterScorePackageType($event) {
    if(this.scoreCardSelectedPackageType.length == 0){
      this.scoreCardSelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onScoreCardPackageTypeChange()
    }
    if ($event.value === 'All') {
      this.scoreCardSelectedPackageType = [];
      return;
    }
  }
  onAddFilterScorePackageType($event) {
    if ($event === 'All') {
      this.scoreCardSelectedPackageType = ['All'];
      return;
    }
    if(this.scoreCardSelectedPackageType.filter(x=> x!='All').length == (this.summaryPackageTypes.length-1)){
      this.scoreCardSelectedPackageType = ['All'];
    }else{
      this.scoreCardSelectedPackageType = this.scoreCardSelectedPackageType.filter(e => e !== 'All');;
    }
  }  
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }

  onScoreCardAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShare();
  }
  handleMarketShare(){
    try
    {
      var weeks = [];

      switch (this.aggregateBy) {
        case 'mtd':
          let month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
          weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];          
          break;
        case 'qtd':
          let quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
          weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))]; 
          break;
        case 'ytd':
          let year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
          weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
          break;
        default:
          weeks = [this.selectedWeek];
          break;
      }  

      let data = lodashClonedeep(this.intage.filter(x=> weeks.includes(x.week)));
      this.marketShareData = [];
      let scoreCardTotal = {
        ty: 0,
        ly: 0,
        tyVolume:0,
        lyVolume:0
      }
      this.marketShareMakers = [{maker:'All'}];
      for (let index = 0; index < this.makerLogo.length; index++) {
        if(!this.scoreCardSelectedCategory.some(x => x == "Energy") && this.makerLogo[index].maker == 'Red Bull'){
          continue;
        }
        this.marketShareMakers.push({
          maker: this.makerLogo[index].maker,
          logo: this.makerLogo[index].logo,
          checked: this.makerLogo[index].checked
        });
      }
      if(this.scoreCardSelectedMaker.some(x => x == "Red Bull") && !this.marketShareMakers.some(x => x.maker == "Red Bull")){
        this.scoreCardSelectedMaker = [this.marketShareMakers[0].maker];
        this.SaveOptionValueTemp(false);
      }
      for(var i=0;i<data.length;i++){
        let row = data[i];
        let process = false;
        if(this.scoreCardSelectedChannel.includes('OTC Total') || this.scoreCardSelectedChannel.includes(row.channel)
          || (this.scoreCardSelectedChannel.includes('SMDD') && (row.channel == 'SM' || row.channel == 'Drug' || row.channel == 'Discounter'))){
            if(this.scoreCardSelectedCategory.includes('Total') || this.scoreCardSelectedCategory.includes(row.category)){
              if(this.scoreCardSelectedRegion.includes('CCBJI Territory') || this.scoreCardSelectedRegion.includes(row.region)){
                if(this.scoreCardSelectedPackageType.includes('All') || this.scoreCardSelectedPackageType.includes(row.packageType)){
                  process = true;
                }
              }
            }
        }
        if(!process){
          continue;
        }
        let maker = this.marketShareData.find(x=> x.maker == row.maker);
        
        if(this.scoreCardSelectedMaker.includes('All') || this.scoreCardSelectedMaker.includes(row.maker)){
          scoreCardTotal.ty +=parseFloat(row.ty);
          scoreCardTotal.ly +=parseFloat(row.ly);
          scoreCardTotal.tyVolume +=parseFloat(row.tyVolume);
          scoreCardTotal.lyVolume +=parseFloat(row.lyVolume);
        }

        // if(row.maker == 'Red Bull' && !this.scoreCardSelectedCategory.some(x => x == "Energy")){
        //   continue;
        // }
        if(maker == null){
          let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
          maker = {
            maker: row.maker,
            index: makerLogo != null ? makerLogo.index : 100,
            logo: makerLogo != null ? makerLogo.logo: "",
            channels: [{
              channel: 'OTC Total',
              categories: [{
                category : 'Total',
                ty: 0,
                ly: 0,
                totalTy: 0,
                totalLy: 0,
                share: '',
                vsLy: '',
                tyVolume: 0,
                lyVolume: 0,
                totalTyVolume: 0,
                totalLyVolume: 0,
                vsLyVolume: ''
              }]
            }]
          };
          this.marketShareData.push(maker);
        }

        let channel = maker.channels.find(x=> x.channel == row.channel);
        if(channel == null){
          channel = {
            channel: row.channel,
            categories: [
              {
                category: 'Total',
                ty: 0,
                ly: 0,
                totalTy: 0,
                totalLy: 0,
                share: '',
                vsLy: '',
                tyVolume: 0,
                lyVolume: 0,
                totalTyVolume: 0,
                totalLyVolume: 0,
                vsLyVolume: ''
              }
            ]
          };
          maker.channels.push(channel);
        }

        let category = channel.categories.find(x=> x.category == row.category);
        if(category == null){
          category = {
            category: row.category,
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            totalTy: 0,
            totalLy: 0,
            share: '',
            vsLy: '',
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume),
            totalTyVolume: 0,
            totalLyVolume: 0,
            vsLyVolume: ''
          };
          channel.categories.push(category);
        }
        else{
          category.ty += parseFloat(row.ty);
          category.ly += parseFloat(row.ly);
          category.tyVolume += parseFloat(row.tyVolume);
          category.lyVolume += parseFloat(row.lyVolume);
        }
        let makerCategory = maker.channels[0].categories.find(x=> x.category == row.category);
        if(makerCategory == null){
          makerCategory = {
            category: row.category,
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            totalTy: 0,
            totalLy: 0,
            share: '',
            vsLy: '',
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume),
            totalTyVolume: 0,
            totalLyVolume: 0,
            vsLyVolume: ''
          };
          maker.channels[0].categories.push(makerCategory);
        }
        else{
          makerCategory.ty += parseFloat(row.ty);
          makerCategory.ly += parseFloat(row.ly);
          makerCategory.tyVolume += parseFloat(row.tyVolume);
          makerCategory.lyVolume += parseFloat(row.lyVolume);
        }
        channel.categories[0].ty+= parseFloat(row.ty);
        channel.categories[0].ly+= parseFloat(row.ly);
        channel.categories[0].tyVolume+= parseFloat(row.tyVolume);
        channel.categories[0].lyVolume+= parseFloat(row.lyVolume);
        maker.channels[0].categories[0].ty+= parseFloat(row.ty);
        maker.channels[0].categories[0].ly+= parseFloat(row.ly);
        maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
        maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
      }

      if (this.scoreCardSelectedCategory.some(x => x == "Total"))
      {
        var othermaker = this.marketShareData.filter(x => x.maker == 'OTHER');
        var redbull = this.marketShareData.filter(x => x.maker == 'Red Bull');      
        if (othermaker && redbull)
        {
          othermaker[0].channels.forEach(channel => {
            channel.categories.forEach(category => {
              if (category.category == 'Total' || category.category == 'Energy')
              {
                category.ty += redbull.filter(x => x.channels.some(y => y.channel == channel.channel && y.categories.some(c => c.category == category.category)))[0].channels[0].categories[0].ty;
                category.ly += redbull.filter(x => x.channels.some(y => y.channel == channel.channel && y.categories.some(c => c.category == category.category)))[0].channels[0].categories[0].ly;
                category.tyVolume += redbull.filter(x => x.channels.some(y => y.channel == channel.channel && y.categories.some(c => c.category == category.category)))[0].channels[0].categories[0].tyVolume;
                category.lyVolume += redbull.filter(x => x.channels.some(y => y.channel == channel.channel && y.categories.some(c => c.category == category.category)))[0].channels[0].categories[0].lyVolume;
              }
            })
          })       
          this.marketShareData  = this.marketShareData.filter(x => x.maker != 'Red Bull');
        }
      }

      for(var i=0;i<this.marketShareData.length;i++){
        let maker = this.marketShareData[i];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            if (category.ly == 0)
            {
              category.vsLy = '-';
            }
            else if (category.ty == 0)
            {
              category.vsLy = '-100.0';
            }
            else
            {
              category.vsLy = ((category.ty/category.ly) * 100 - 100).toFixed(1);
            }
  
            if (category.lyVolume == 0)
            {
              category.vsLyVolume = '-';
            }
            else if (category.tyVolume == 0)
            {
              category.vsLyVolume = '-100.0';
            }
            else
            {
              category.vsLyVolume = ((category.tyVolume/category.lyVolume) * 100 - 100).toFixed(1);
            }
          }
        }
      }

      this.marketShareData.sort((a, b) => a.index > b.index ? 1 : -1);
      if (scoreCardTotal.ly == 0)
      {
        this.scoreCardData.vsLy = '-';
      }
      else if (scoreCardTotal.ty == 0)
      {
        this.scoreCardData.vsLy = '-100.0';
      }
      else 
      {
        this.scoreCardData.vsLy = ((scoreCardTotal.ty/scoreCardTotal.ly) * 100 - 100).toFixed(1);
      }
  
      if (scoreCardTotal.lyVolume == 0)
      {
        this.scoreCardData.vsLyVolume = '-';
      }
      else if (scoreCardTotal.tyVolume == 0)
      {
        this.scoreCardData.vsLyVolume = '-100.0';
      }
      else 
      {
        this.scoreCardData.vsLyVolume = ((scoreCardTotal.tyVolume/scoreCardTotal.lyVolume) * 100 - 100).toFixed(1);
      }
    }
    catch(e){

    }
    let t= this;
    setTimeout(() => {
      t.ready.emit(true);
    });    
  }
  
  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }
  public onScoreCardMakerChange(){
    if(this.scoreCardSelectedMaker.length == 0){
      this.scoreCardSelectedMaker=['All'];
    }
    this.handleMarketShare();
  }

  public onScoreCardChannelChange(){
    if(this.scoreCardSelectedChannel.length==0){
      this.scoreCardSelectedChannel=['OTC Total'];
    }
    this.handleMarketShare();
  }

  public onScoreCardCategoryChange(){
    if(this.scoreCardSelectedCategory.length==0){
      this.scoreCardSelectedCategory=['Total'];
    }
    this.handleMarketShare();
  }

  public onScoreCardRegionChange(){
    if(this.scoreCardSelectedRegion.length==0){
      this.scoreCardSelectedRegion= ['CCBJI Territory'];
    }
    this.handleMarketShare();
  }

  public onScoreCardPackageTypeChange(){
    if(this.scoreCardSelectedPackageType.length==0){
      this.scoreCardSelectedPackageType=['All'];
    }
    this.handleMarketShare();
  }
}
