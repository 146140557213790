import { Component, Renderer2, OnInit, Inject, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as tsvParse from "d3-dsv";
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { AnalyticsQueries, tokens } from 'src/app/model/constants';
import Swal from 'sweetalert2';
import { processHide, processShow } from 'src/app/helpers/process';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import * as moment from 'moment';
import { getAggregateByFromUrl, getSelectedDateFromUrl } from 'src/app/helpers/queryString';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  data: any;
  startDate: any;
  selectedDate: any;
  ready: boolean;
  nsrSales: any;
  dailyData: any;
  dailyDataHist: any;
  monthlyData: any;
  monthlyDataHist: any;
  monthlyFullData: any;
  summaryReady: boolean;
  nsrTrendReady: boolean;
  nsrDailyReady: boolean;
  nsrMonthlyReady: boolean;
  gpMonthlyReady: boolean;
  salePerformance: boolean;
  aggregateBy: string;
  imageProcess: string;
  titleProcess: string;
  desProcess: string;
  percentageProcess: number;
  processReady: boolean;
  constructor(private router: Router, public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }

  ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
      this.summaryReady = false;
      this.nsrTrendReady = false;
      this.nsrDailyReady = false;
      this.nsrMonthlyReady = false;
      this.salePerformance = false;
      this.aggregateBy = 'daily';

      let queryUrl = environment.hygraphGetJobUpdate;
      let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
      }
      this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
          response => {
              let res = JSON.parse(response);
              if (res.data[environment.clearCacheJobStatus] != null && res.data[environment.clearCacheJobStatus].length > 0)
                {
                  let latest_date = res.data[environment.clearCacheJobStatus][0].date;
                  let year = parseInt(latest_date.substring(0,4));
                  let month = parseInt(latest_date.substring(4,6));
                  let day = parseInt(latest_date.substring(6,8));
                  this.selectedDate = {
                    year: year,
                    month: month,
                    day: day
                  };
                  this.startDate = {
                    year: year,
                    month: month,
                    day: day
                  };
                }
                this.bindIconProcess();
                this.bindNsrSalesTrendChart();
                this.bindNsrSalesHistTrendChart();

                const selectedDate = getSelectedDateFromUrl(this.router);
                if (selectedDate) {
                  this.selectedDate = selectedDate;
                }

                const aggregateBy = getAggregateByFromUrl(this.router);
                if (aggregateBy) {
                  this.aggregateBy = aggregateBy;
                }
          },
          error =>
          {
              console.log(error);
          }
      );

      this.clearOldData();
    }
    //Init data
  }

  ngOnDestroy() {
    processHide();
  }

  async bindNsrSalesTrendChart(){
    // monthly chart
    if(this.monthlyData == null){
      let today = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month +
      (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
      this.monthlyData = await IndexDbHelper.Get('MonthlySales_' + today);
      let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
      }
      let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.monthlySales +"&date=" + today;
      this.http.get<any>(queryUrl, httpOptions)
        .subscribe(
          response => {
            let monthlyData = tsvParse.tsvParse('month	channelCode	regionCode	districtCode	bapc_ty	bapc_bp	bapc_ly	nsr_ty	nsr_bp	nsr_ly	gp_ty	gp_bp	gp_ly\n' + response);
            monthlyData = this.filter(monthlyData);
            if(this.monthlyData == null){
              this.monthlyData = monthlyData;
            }
            IndexDbHelper.Set('MonthlySales_' + today, monthlyData);
          },
          error => {
            console.log(error);
          }
        );

      // startYear = (this.selectedDate.year-1) + '0101';
      this.monthlyFullData = await IndexDbHelper.Get('MonthlyFullSales_' + today);
      queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.monthlyFullSales +"&date=" + today;
      this.http.get<any>(queryUrl, httpOptions)
        .subscribe(
          response => {
            let monthlyFullData = tsvParse.tsvParse('month	channelCode	regionCode	districtCode	bapc_ty	bapc_bp	bapc_ly	nsr_ty	nsr_bp	nsr_ly	gp_ty	gp_bp	gp_ly\n' + response);

            monthlyFullData = this.filter(monthlyFullData);

            if(this.monthlyFullData == null){
              this.monthlyFullData = monthlyFullData;
            }
            IndexDbHelper.Set('MonthlyFullSales_' + today, monthlyFullData);
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  public filter(data: any)
  {
    data = data.filter(x => {
      if (x.channelCode == '' && (x.regionCode != '7200' || x.districtCode != '7200'))
      {
        return false;
      }
      if (AnalyticsQueries.wrongNullDistrictCodeRegionCode.includes(x.channelCode) && (x.regionCode == '' || x.districtCode == ''))
      {
        return false;
      }
      if (AnalyticsQueries.wrong9999DistrictCode.includes(x.channelCode) && (x.regionCode == '9999' || x.districtCode == '9999'))
      {
        return false;
      }
      if (x.regionCode == '6000' && x.districtCode == '6280')
      {
        return false;
      }
      if (x.regionCode == '5000' && x.districtCode == '6300')
      {
        return false;
      }
      return true;
    });

    data.forEach(x => {
      if (x.channelCode == '411' && AnalyticsQueries.wrong411Channels.includes(x.regionCode))
      {
        x.regionCode = '9100';
      }

      // if (x.regionCode == '9100' && x.districtCode == '9100' && AnalyticsQueries.wrongChannelCodeOther.includes(x.channelCode))
      // {
      //   x.regionCode = '7100';
      //   x.districtCode = '7100';
      // }

      // if (x.regionCode == '7200' && x.districtCode == '7200' && AnalyticsQueries.wrongChannelCodeAlcohol.includes(x.channelCode))
      // {
      //   x.channelCode = '411';
      // }

      if (x.channelCode == '' && x.regionCode == '7200' && x.districtCode == '7200')
      {
        x.channelCode = '411';
      }

      if (x.regionCode == '7200' && (x.channelCode == '331' || x.channelCode == '332'))
      {
        x.regionCode = '7100';
        x.districtCode = '7100';
      }
    });

    return data;
  }
  async bindNsrSalesHistTrendChart(){
    // monthly chart
    if(this.dailyDataHist == null){
      let today = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month +
      (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
      this.dailyDataHist = await IndexDbHelper.Get('DailySalesHist_' + today);
      let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.dailySalesHist +"&date=" + today;
      let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
      }
      this.http.get<any>(queryUrl, httpOptions)
        .subscribe(
          response => {
            let dailyDataHist = tsvParse.tsvParse('date	channelCode	regionCode	districtCode	bapc_ty	bapc_bp	bapc_ly	nsr_ty	nsr_bp	nsr_ly	gp_ty	gp_bp	gp_ly\n' + response);

            dailyDataHist = this.filter(dailyDataHist);
            if(this.dailyDataHist== null){
              this.dailyDataHist = dailyDataHist;
            }
            IndexDbHelper.Set('DailySalesHist_' + today, dailyDataHist);
          },
          error => {
            console.log(error);
          }
        );

      let startYear = (this.selectedDate.year-1) + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month + '01';
      let isFeb29 = this.selectedDate.month == 2 && this.selectedDate.day == 29;
      let today2019 = isFeb29 ? '20190228' : '2019' + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month +
      (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
      let nextMonth2019 = this.selectedDate.month == 12 ? '20200101' : '2019' + (this.selectedDate.month > 8 ? '' : '0') + (this.selectedDate.month + 1) + '01';

      this.monthlyDataHist = await IndexDbHelper.Get('MonthlySalesHist_'+today);
      queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.monthlySalesHist +"&date=" + today;
      this.http.get<any>(queryUrl, httpOptions)
        .subscribe(
          response => {
            let monthlyDataHist = tsvParse.tsvParse('month	channelCode	regionCode	districtCode	bapc_ty	bapc_bp	bapc_ly	nsr_ty	nsr_bp	nsr_ly	gp_ty	gp_bp	gp_ly\n' + response);

            monthlyDataHist = this.filter(monthlyDataHist);
            if(this.monthlyDataHist == null){
              this.monthlyDataHist = monthlyDataHist;
            }
            IndexDbHelper.Set('MonthlySalesHist_'+today, monthlyDataHist);
          },
          error => {
            console.log(error);
          }
        );
      }
  }
  public onDateSelect(event) {
    dispatchDateSelectChange({...event, ...{element:"dashboard"}});
    this.summaryReady = false;
    this.nsrTrendReady = false;
    this.nsrDailyReady = false;
    this.nsrMonthlyReady = false;
    this.salePerformance = false;
    this.dailyData = null;
    this.dailyDataHist = null;
    this.monthlyData = null;
    this.monthlyDataHist = null;
    this.monthlyFullData = null;
    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth();
    let date = selectedDate.getDate();
    localStorage.setItem('selectedDate', year + ',' + month + ',' + date);
    this.bindNsrSalesTrendChart();
    this.bindNsrSalesHistTrendChart();
    this.bindIconProcess();
  }
  public onAggregateByChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
  }
  public bindIconProcess() {
    this.processReady = false;
    var currentDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let startDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    startDate.setDate(1);
    let today = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month +
    (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
    let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.percentPerformance + "&date=" + today;
    // let startMonth = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month + '01';
    // let month = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month;
    // queryUrl = queryUrl.replace('{0}', startMonth).replace('{0}', startMonth).replace('{1}', today).replace('{2}', month);
    var accessToken = 'Bearer ' + localStorage.getItem('accessToken');
    let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', accessToken),
      responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
        response => {
          let arr = tsvParse.tsvParse('act	id\n' + response);
          if(arr[0].act == ''){
            arr[0].act = 0;
          }
          if(arr.length > 1 && arr[1].act == ''){
            arr[1].act = 0;
          }
          let monthBp = arr.length > 1 ? parseFloat(arr[1].act) : 0;
          let percent = monthBp == 0 ? 100 : Math.round((parseFloat(arr[0].act) / monthBp) * 100);
          this.titleProcess = 'Progress from <b>' + startDate.getFullYear() + '/' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '/' + "0" + startDate.getDate() + '</b> to <b>'
            + currentDate.getFullYear() + '/' + ("0" + (currentDate.getMonth() + 1)).slice(-2) + '/' + ("0" + currentDate.getDate()).slice(-2) + '</b><br/>' + (parseFloat(arr[0].act) / 1000000).toFixed(1) + ' MM / ' + (monthBp / 1000000).toFixed(1) + ' MM';
          this.percentageProcess = percent;
          this.desProcess = '*This chart is using the most updated master data and transaction data from HANA';
          if (percent < 10) {
            this.imageProcess = 'assets/img/image_process/0_percent.png';
          } else if (percent < 20) {
            this.imageProcess = 'assets/img/image_process/10_percent.png';
          } else if (percent < 30) {
            this.imageProcess = 'assets/img/image_process/20_percent.png';
          } else if (percent < 40) {
            this.imageProcess = 'assets/img/image_process/30_percent.png';
          } else if (percent < 50) {
            this.imageProcess = 'assets/img/image_process/40_percent.png';
          } else if (percent < 60) {
            this.imageProcess = 'assets/img/image_process/50_percent.png';
          } else if (percent < 70) {
            this.imageProcess = 'assets/img/image_process/60_percent.png';
          } else if (percent < 80) {
            this.imageProcess = 'assets/img/image_process/70_percent.png';
          } else if (percent < 90) {
            this.imageProcess = 'assets/img/image_process/80_percent.png';
          } else if (percent < 100) {
            this.imageProcess = 'assets/img/image_process/90_percent.png';
          } else {
            this.imageProcess = 'assets/img/image_process/perfect_percent.png';
          };
          this.processReady = true;

          processShow({ imageProcess: this.imageProcess, titleProcess: this.titleProcess, percentageProcess: this.percentageProcess, desProcess: this.desProcess });

        },
        error => {
          console.log(error);
        }
      );
  }

  public summaryCheckReady(ready: boolean) {
    this.summaryReady = ready;
    this.checkComplete();
  }
  public nsrTrendCheckReady(ready: boolean) {
    this.nsrTrendReady = ready;
    this.checkComplete();
  }
  public nsrDailyCheckReady(ready: boolean) {
    this.nsrDailyReady = ready;
    this.checkComplete();
  }
  public nsrMonthlyCheckReady(ready: boolean) {
    this.nsrMonthlyReady = ready;
    this.checkComplete();
  }
  public gpMonthlyCheckReady(ready: boolean) {
    this.gpMonthlyReady = ready;
    this.checkComplete();
  }
  public salePerformanceCheckReady(ready: boolean) {
    this.salePerformance = ready;
    this.checkComplete();
  }

  checkComplete(){
    if(this.summaryReady && this.nsrTrendReady && this.nsrDailyReady && this.nsrMonthlyReady && this.gpMonthlyReady && this.salePerformance){
      //Fake resize as somehow some chart doesn't render until screen size changed
      window.dispatchEvent(new Event('resize'));
    }
  }

  async clearOldData() {
    try {
      let dbNames = ['DailySalesHist', 'MonthlySalesHist', 'MonthlyFullSales', 'MonthlySales'];
      await IndexDbHelper.DeleteOldData(dbNames);
    } catch (error) {
      console.log(error);
    }
  }
}
