import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {AnalyticsHelper} from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DropdownValueToNgSelectFormat } from 'src/app/helpers/dropdown';
import { getColorsWithMovingAverage, getSeriesWithMovingAverage } from 'src/app/helpers/movingAverage';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
@Component({
  selector: 'intage-share-vs-ly',
  templateUrl: './share-vs-ly.component.html',
  styleUrls: ['./share-vs-ly.component.scss']
})
export class IntageShareVsLyComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  otcChannels: any;
  otcWeeks: any;
  otcStartWeeks: number;  
  categoryColors: any;
  categoriesFilter: any;
  makerLogo: any;
  shareVsLyDisplayType: string;
  marketShareVsLyChart: ApexCharts;
  marketShareVsLyHaveSingleSerie: boolean;
  marketShareVsLyHaveData: boolean;
  marketShareVsLySingleSerie: string;
  marketShareVsLySingleSerieColor: string;
  marketShareVsLyMakers: any;
  summaryRegions: any;
  summaryPackageTypes: any;
  shareVsLySelectedChannel: any;
  shareVsLySelectedCategory: any;
  shareVsLySelectedRegion: any;
  shareVsLySelectedPackageType: any;
  marketShareVsLyData: any;
  populated: boolean;
  series: any;
  aggregateBy: any;
  otcMonths: any;
  otcQuarters: any;
  otcYears: any;
  movingAverageWindowSizes: number[] = [4];
  chartId = "share-diff-vs-ly";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges() {
      if(!this.populated){
        this.shareVsLySelectedChannel = ['OTC Total'];
        this.shareVsLySelectedCategory = ['Total'];
        this.shareVsLySelectedRegion = ['CCBJI Territory'];
        this.shareVsLySelectedPackageType = ['All'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.shareVsLyDisplayType = 'value';
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareVsLyMakers = [];
        this.makerLogo.forEach(x => {
          this.marketShareVsLyMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked,
            show: x.maker != 'Red Bull'
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.categoriesFilter = IntageHelper.Categories();
        this.otcChannels = IntageHelper.OTCChannels();
        this.summaryPackageTypes = IntageHelper.PackageType();
        this.summaryRegions = IntageHelper.Regions();
        this.otcStartWeeks = 52;
        this.handleMarketShareVsLy();
        // Reset the start week when end date changed
  
        //this.renderMarketShareVsLyTimelineChart();  
      }

          
  }
  filterMakers(makers: any): any {
    return makers.filter(e => e.show);
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["shareVsLySelectedChannel"] = [...this.shareVsLySelectedChannel];
      window["shareVsLySelectedCategory"] = [...this.shareVsLySelectedCategory];
      window["shareVsLySelectedRegion"] = [...this.shareVsLySelectedRegion];
      window["shareVsLySelectedPackageType"] = [...this.shareVsLySelectedPackageType];
    } else {
      if (window["shareVsLySelectedChannel"] != null) { this.shareVsLySelectedChannel = window["shareVsLySelectedChannel"]; }
      if (window["shareVsLySelectedCategory"] != null) { this.shareVsLySelectedCategory = window["shareVsLySelectedCategory"]; }
      if (window["shareVsLySelectedRegion"] != null) { this.shareVsLySelectedRegion = window["shareVsLySelectedRegion"]; }
      if (window["shareVsLySelectedPackageType"] != null) { this.shareVsLySelectedPackageType = window["shareVsLySelectedPackageType"]; }
    }
  }
  onRemoveFilterLyChannel($event) {
    if(this.shareVsLySelectedChannel.length == 0){
      this.shareVsLySelectedChannel = ['OTC Total'];
      this.SaveOptionValueTemp(false);
      this.onShareVsLyChannelChange()
    }
    if ($event.value === 'OTC Total') {
      this.shareVsLySelectedChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event.value);
      this.shareVsLySelectedChannel = this.shareVsLySelectedChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.shareVsLySelectedChannel = this.shareVsLySelectedChannel.filter(e => e !== parent);
    }
  }
  onAddFilteLyChannel($event) {
    if ($event === 'OTC Total') {
      this.shareVsLySelectedChannel = ['OTC Total'];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event);
      this.shareVsLySelectedChannel = this.shareVsLySelectedChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChildIntage(parent);
      arrChilds.forEach(child => {
        if (this.otcChannels.some(x=>x.channel==child) && !this.shareVsLySelectedChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.shareVsLySelectedChannel = this.shareVsLySelectedChannel.filter(e => !arrChilds.includes(e));
        this.shareVsLySelectedChannel = arrTemp.concat(this.shareVsLySelectedChannel);
      }else{
        this.shareVsLySelectedChannel = this.shareVsLySelectedChannel.filter(e => e !== parent);
      }
    }
    var checkParentFilter = true;
    var filterNotCheck = this.otcChannels.filter(e => !this.shareVsLySelectedChannel.includes(e.channel) && e.channel!='OTC Total');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.channel) || !this.shareVsLySelectedChannel.includes(AnalyticsHelper.getSmddParent(element.channel))) {
        checkParentFilter=false;
      }
    });
    if(checkParentFilter){
      this.shareVsLySelectedChannel = ['OTC Total'];
    }else{
      this.shareVsLySelectedChannel = this.shareVsLySelectedChannel.filter(e => e !== 'OTC Total');
    }
  }  
  onRemoveFilteLyCategory($event) {
    if(this.shareVsLySelectedCategory.length == 0){
      this.shareVsLySelectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onShareVsLyCategoryChange()
    }
    if ($event.value === 'Total') {
      this.shareVsLySelectedCategory = [];
      return;
    }
  }
  onAddFilterLyCategory($event) {
    if ($event === 'Total') {
      this.shareVsLySelectedCategory = ['Total'];
      return;
    }
    if(this.shareVsLySelectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.shareVsLySelectedCategory = ['Total'];
    }else{
      this.shareVsLySelectedCategory = this.shareVsLySelectedCategory.filter(e => e !== 'Total');;
    }
  }
  onRemoveFilterLyRegion($event) {
    if(this.shareVsLySelectedRegion.length == 0){
      this.shareVsLySelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
    }
    if ($event.value === 'CCBJI Territory') {
      this.shareVsLySelectedRegion = [];
      return;
    }
  }
  onAddFilterLyRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.shareVsLySelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.shareVsLySelectedRegion.filter(x=> x != 'CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.shareVsLySelectedRegion = ['CCBJI Territory'];
    }else{
      this.shareVsLySelectedRegion = this.shareVsLySelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  }
  onRemoveFilterLyPackageType($event) {
    if(this.shareVsLySelectedPackageType.length == 0){
      this.shareVsLySelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onShareVsLyPackageTypeChange()
    }
    if ($event.value === 'All') {
      this.shareVsLySelectedPackageType = [];
      return;
    }
  }
  onAddFilterLyPackageType($event) {
    if ($event === 'All') {
      this.shareVsLySelectedPackageType = ['All'];
      return;
    }
    if(this.shareVsLySelectedPackageType.filter(x=> x!='All').length == (this.summaryPackageTypes.length-1)){
      this.shareVsLySelectedPackageType = ['All'];
    }else{
      this.shareVsLySelectedPackageType = this.shareVsLySelectedPackageType.filter(e => e !== 'All');;
    }
  } 
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }

  onSharevslyAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShareVsLy();
  }

  handleMarketShareVsLy(){
    try
    {
      this.otcWeeks = [...new Set(this.intage.map(x=> x.week))];   
      let data = [];
      this.marketShareVsLyMakers.find(x=>x.maker == 'Red Bull').show = this.shareVsLySelectedCategory.some(x => x == "Energy");
      if(!this.shareVsLySelectedCategory.some(x => x == "Energy")){
        this.marketShareVsLyMakers.find(x=>x.maker == 'Red Bull').checked = false;
      }
      let selectedWeekInt = parseInt(this.selectedWeek.replace('-','').replace('-',''));
      for(var i = 0;i<this.intage.length;i++){
        let row = this.intage[i];
        if(parseInt(row.week.replace('-','').replace('-','')) > selectedWeekInt){
          continue;
        }
        else {
          if(this.shareVsLySelectedChannel.includes('OTC Total') || this.shareVsLySelectedChannel.includes(row.channel)
          || (this.shareVsLySelectedChannel.includes('SMDD') && (row.channel == 'SM' || row.channel == 'Drug' || row.channel == 'Discounter'))){
            if(this.shareVsLySelectedCategory.includes('Total') || this.shareVsLySelectedCategory.includes(row.category)){
              if(this.shareVsLySelectedRegion.includes('CCBJI Territory') || this.shareVsLySelectedRegion.includes(row.region)){
                  data.push(row);              
              }
            }
          }
        }
      }

      this.otcWeeks = [...new Set(data.map(x=> x.week))];

      switch (this.aggregateBy) {
        case 'mtd':
          this.handleIntageOTCMonthly(data);
          break;
        case 'qtd':
          this.handleIntageOTCQuarterly(data);          
          break;
        case 'ytd':
          this.handleIntageOTCYearly(data);
          break;
        default:
          this.handleIntageOTCWeekly(data);
          break;
      }      
    }
    catch(e){

    }
    let t= this;
      setTimeout(() => {
        t.ready.emit(true);
      });
    
  }

  handleIntageOTCWeekly(data: any)
  {
    this.marketShareVsLyData = [];
    let totals = [];
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }
      let week = this.marketShareVsLyData.find(x=> x.week == row.week);
      if(week == null){
        week = {
          week: row.week,
          makers: []
        }
        this.marketShareVsLyData.push(week);
      }
      let maker = week.makers.find(x=> x.maker == row.maker);
      
      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      if(this.shareVsLySelectedPackageType != 'All' && this.shareVsLySelectedPackageType != row.packageType){
        continue;
      }

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        week.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    for(var i=0;i<this.marketShareVsLyData.length;i++){
      let week = this.marketShareVsLyData[i];
      for(var m=0;m<week.makers.length;m++){
        let maker = week.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalWeek = totals.find(x=> x.week == week.week);
            let total = totalWeek.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;

            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
            let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
            let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';  
            category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_volume_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_volume_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_volume_noround == null || shareLy_volume_noround == null) ? '-' : (parseFloat(share_volume_noround) - parseFloat(shareLy_volume_noround)).toFixed(1);
          }
        }
      }
      week.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }   
    this.marketShareVsLyData.reverse();
    this.otcWeeks.reverse();
    this.renderMarketShareVsLyTimelineChart(this.otcWeeks.slice(-1 * this.otcStartWeeks));
  }

  handleIntageOTCMonthly(data: any)
  {
    this.marketShareVsLyData = [];
    this.otcMonths = [];
    let totals = [];
    var start_week = this.otcWeeks[this.otcStartWeeks - 1];
    var intage_month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week));
    if (start_week != null)
    {
      data = data.filter(x => x.week >= start_week);
    }
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }

      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      var month = intage_month.find(x => x.week == row.week).month;

      if (!this.otcMonths.includes(month))
      {
        this.otcMonths.push(month);
      }
      
      var totalMonth = totals.find(x => x.month == month);
      if (totalMonth == null)
      {
        totalMonth = {
          month: month,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume)
          }]
        }
        totals.push(totalMonth);
      }
      else
      {
        totalMonth.data[0].ty += parseFloat(row.ty);
        totalMonth.data[0].ly += parseFloat(row.ly);
        totalMonth.data[0].tyVolume += parseFloat(row.tyVolume);
        totalMonth.data[0].lyVolume += parseFloat(row.lyVolume);
      }

      if(this.shareVsLySelectedPackageType != 'All' && this.shareVsLySelectedPackageType != row.packageType){
        continue;
      }

      var month_data = this.marketShareVsLyData.find(x => x.month == month);
      if (month_data == null)
      {
        month_data = {
          month : month,
          makers: []
        }
        this.marketShareVsLyData.push(month_data);
      }
      let maker = month_data.makers.find(x=> x.maker == row.maker);

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        month_data.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    totals = totals.filter(x => x.month != null);

    for(var i=0;i<this.marketShareVsLyData.length;i++){
      let month = this.marketShareVsLyData[i];
      for(var m=0;m<month.makers.length;m++){
        let maker = month.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalMonth = totals.find(x=> x.month == month.month);
            let total = totalMonth.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              
            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
            let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
            let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';  
            category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_volume_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_volume_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_volume_noround == null || shareLy_volume_noround == null) ? '-' : (parseFloat(share_volume_noround) - parseFloat(shareLy_volume_noround)).toFixed(1);
          }
        }
      }
      month.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareVsLyData.reverse();
    this.otcWeeks.reverse();
    this.otcMonths.reverse();
    this.renderMarketShareVsLyTimelineChart(this.otcMonths);
  }

  handleIntageOTCQuarterly(data: any)
  {
    this.marketShareVsLyData = [];
    this.otcQuarters = [];
    let totals = [];
    var start_week = this.otcWeeks[this.otcStartWeeks - 1];
    var intage_month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week));
    if (start_week != null)
    {
      data = data.filter(x => x.week >= start_week);
    }
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }

      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      var quarter = intage_month.find(x => x.week == row.week).quarter;

      if (!this.otcQuarters.includes(quarter))
      {
        this.otcQuarters.push(quarter);
      }
      
      var totalQuarter = totals.find(x => x.quarter == quarter);
      if (totalQuarter == null)
      {
        totalQuarter = {
          quarter: quarter,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume)
          }]
        }
        totals.push(totalQuarter);
      }
      else
      {
        totalQuarter.data[0].ty += parseFloat(row.ty);
        totalQuarter.data[0].ly += parseFloat(row.ly);
        totalQuarter.data[0].tyVolume += parseFloat(row.tyVolume);
        totalQuarter.data[0].lyVolume += parseFloat(row.lyVolume);
      }

      if(this.shareVsLySelectedPackageType != 'All' && this.shareVsLySelectedPackageType != row.packageType){
        continue;
      }

      var quarter_data = this.marketShareVsLyData.find(x => x.quarter == quarter);
      if (quarter_data == null)
      {
        quarter_data = {
          quarter : quarter,
          makers: []
        }
        this.marketShareVsLyData.push(quarter_data);
      }
      let maker = quarter_data.makers.find(x=> x.maker == row.maker);

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        quarter_data.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    totals = totals.filter(x => x.quarter != null);

    for(var i=0;i<this.marketShareVsLyData.length;i++){
      let quarter = this.marketShareVsLyData[i];
      for(var m=0;m<quarter.makers.length;m++){
        let maker = quarter.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalQuarter = totals.find(x=> x.quarter == quarter.quarter);
            let total = totalQuarter.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              
            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
            let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
            let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';  
            category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_volume_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_volume_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_volume_noround == null || shareLy_volume_noround == null) ? '-' : (parseFloat(share_volume_noround) - parseFloat(shareLy_volume_noround)).toFixed(1);
          }
        }
      }
      quarter.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareVsLyData.reverse();
    this.otcWeeks.reverse();
    this.otcQuarters.reverse();
    this.renderMarketShareVsLyTimelineChart(this.otcQuarters);
  }

  handleIntageOTCYearly(data: any)
  {
    this.marketShareVsLyData = [];
    this.otcYears = [];
    let totals = [];
    var start_week = this.otcWeeks[this.otcStartWeeks - 1];
    var intage_month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week));
    if (start_week != null)
    {
      data = data.filter(x => x.week >= start_week);
    }
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }

      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      var year = intage_month.find(x => x.week == row.week).year;

      if (!this.otcYears.includes(year))
      {
        this.otcYears.push(year);
      }
      
      var totalYear = totals.find(x => x.year == year);
      if (totalYear == null)
      {
        totalYear = {
          year: year,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume)
          }]
        }
        totals.push(totalYear);
      }
      else
      {
        totalYear.data[0].ty += parseFloat(row.ty);
        totalYear.data[0].ly += parseFloat(row.ly);
        totalYear.data[0].tyVolume += parseFloat(row.tyVolume);
        totalYear.data[0].lyVolume += parseFloat(row.lyVolume);
      }

      if(this.shareVsLySelectedPackageType != 'All' && this.shareVsLySelectedPackageType != row.packageType){
        continue;
      }

      var year_data = this.marketShareVsLyData.find(x => x.year == year);
      if (year_data == null)
      {
        year_data = {
          year : year,
          makers: []
        }
        this.marketShareVsLyData.push(year_data);
      }
      let maker = year_data.makers.find(x=> x.maker == row.maker);

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        year_data.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    totals = totals.filter(x => x.year != null);

    for(var i = 0;i < this.marketShareVsLyData.length; i++){
      let year = this.marketShareVsLyData[i];
      for(var m = 0;m < year.makers.length; m++){
        let maker = year.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalYear = totals.find(x=> x.year == year.year);
            let total = totalYear.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              
            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
            let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
            let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';  
            category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_volume_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_volume_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_volume_noround == null || shareLy_volume_noround == null) ? '-' : (parseFloat(share_volume_noround) - parseFloat(shareLy_volume_noround)).toFixed(1);
          }
        }
      }
      year.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareVsLyData.reverse();
    this.otcWeeks.reverse();
    this.otcYears.reverse();
    this.renderMarketShareVsLyTimelineChart(this.otcYears);
  }

  renderMarketShareVsLyTimelineChart(categories: any){
    if(this.marketShareVsLyChart != null){
      this.marketShareVsLyChart.destroy();
    }

    this.series = [];
    let colors = [];
    for(var i=0;i<this.marketShareVsLyMakers.length;i++){
      let maker = this.marketShareVsLyMakers[i];
      if(maker.checked){
        let serie = {
          name : maker.maker,
          data : []
        };
        for(var j=0;j<this.marketShareVsLyData.length;j++){
          let currentMaker = this.marketShareVsLyData[j].makers.find(x=> x.maker == maker.maker);
          if(currentMaker == null){
            serie.data.push('-');
            continue;
          }
          if(this.shareVsLyDisplayType == 'value'){
            serie.data.push(currentMaker.channels[0].categories[0].vsLy);
          }else{
            serie.data.push(currentMaker.channels[0].categories[0].vsLyVolume);
          }
        }
        
        this.series.push(serie);
        colors.push(IntageHelper.GetMakerColor(serie.name));
      }
    }
    this.marketShareVsLyHaveData = false;
    for(var i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.marketShareVsLyHaveData = true;
        break;
      }
    }
    this.marketShareVsLyHaveSingleSerie = false;
    if(this.marketShareVsLyHaveData && this.series.length == 1){
      this.marketShareVsLyHaveSingleSerie = true;
      this.marketShareVsLySingleSerie = this.series[0].name;
      this.marketShareVsLySingleSerieColor = colors[0];
    }
    // otcStartWeeks    
    for (let i = 0; i < this.series.length; i++) {
      this.series[i]['data'] = this.series[i]['data'].slice(-1 * this.otcStartWeeks)      
    }
    const options = {
        chart: {
            height: 500,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
              show: true,
              tools: {
                download: false
              }
            }
        },
        dataLabels: {
            enabled: this.marketShareVsLyHaveSingleSerie ? true: false,
            style:{
              colors: [
                function (w) {
                  if (w.series[w.seriesIndex][w.dataPointIndex] > 0) {
                    return w.w.config.colors[w.seriesIndex];
                  } else {
                    return "#FF2626";
                  }
                },
              ],
              fontSize : "14px"
            }
        },
        stroke: {
            //width: [5, 5, 5],
            curve: 'smooth',
            //dashArray: [5, 5, 5]
        },
        colors: getColorsWithMovingAverage({ colors, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        series: getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: true
            }
        },
        markers: {
            size: this.marketShareVsLyHaveSingleSerie ? 0 : 5,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: categories
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        yaxis: {
          forceNiceScale: true,
          //min: -50
        }
      }; 
    // FPT-ChienNX7 [2022/12/14][SENSE][1203444563290052] Rename file name png when export start

    document.querySelector("#intage-market-share-vsLy-chart").innerHTML = '';
    this.marketShareVsLyChart = new ApexCharts(
        document.querySelector("#intage-market-share-vsLy-chart"),
        options
    );
    this.marketShareVsLyChart.render();
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }
  public onShareVsLyMakersCheckedChanged(maker: string){
    let currentMaker = this.marketShareVsLyMakers.find(x=> x.maker == maker);
    currentMaker.checked = !currentMaker.checked;
    this.handleMarketShareVsLy();
  }

  public onShareVsLyDisplayTypeChange(type: string){
    this.shareVsLyDisplayType = type;
    this.handleMarketShareVsLy();
  }
  public onShareVsLyChannelChange(){
    if(this.shareVsLySelectedChannel.length==0){
      this.shareVsLySelectedChannel = ['OTC Total'];
    }
    this.handleMarketShareVsLy();
  }

  public onShareVsLyCategoryChange(){
    if(this.shareVsLySelectedCategory.length==0){
      this.shareVsLySelectedCategory = ['Total'];
    }
    this.handleMarketShareVsLy();
  }

  public onShareVsLyRegionChange(){
    if(this.shareVsLySelectedRegion.length==0){
      this.shareVsLySelectedRegion = ['CCBJI Territory'];
    }
    this.handleMarketShareVsLy();
  }

  public onShareVsLyPackageTypeChange(){
    if(this.shareVsLySelectedPackageType.length==0){
      this.shareVsLySelectedPackageType = ['All'];
    }
    this.handleMarketShareVsLy();
  }
 
  public onSelectedStartWeekChanged($event){
    this.otcStartWeeks = $event;
    this.handleMarketShareVsLy();
  } 

  showOptionDownload() {
    const download = document.getElementById('download-option-share-vs-ly');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  public exportSharevsLY(isImage: boolean) {
    let weeks = this.otcWeeks.slice(-1 * this.otcStartWeeks);
    switch (this.aggregateBy) {
      case 'mtd':
        var fileName = 'Share Value vs LY From ' +  this.otcMonths[0] + ' To ' + this.otcMonths[this.otcMonths.length - 1];  
        break;
      case 'qtd':
        var fileName = 'Share Value vs LY From ' +  this.otcQuarters[0] + ' To ' + this.otcQuarters[this.otcQuarters.length - 1];       
        break;
      case 'ytd':
        var fileName = 'Share Value vs LY From ' +  this.otcYears[0] + ' To ' + this.otcYears[this.otcYears.length - 1];
        break;
      default:
        var fileName = 'Share Value vs LY From ' +  weeks[0] + ' To ' + weeks[weeks.length - 1];
        break;
    }

    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      switch (this.aggregateBy) {
        case 'mtd':
          for (var i = 0; i < this.otcMonths.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  month: this.otcMonths[i],
                  maker: this.series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareVsLySelectedChannel,
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,
                  packagetype: this.shareVsLySelectedPackageType,              
                  diffLy: this.series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Month", "Maker", "Display", "Channel", "Category", "Region", "Package Type", "Diff.LY"]
          });
          break;
        case 'qtd':
          for (var i = 0; i < this.otcQuarters.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  quarter: this.otcQuarters[i],
                  maker: this.series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareVsLySelectedChannel,
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,
                  packagetype: this.shareVsLySelectedPackageType,              
                  diffLy: this.series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Quarter", "Maker", "Display", "Channel", "Category", "Region", "Package Type", "Diff.LY"]
          });   
          break;
        case 'ytd':
          for (var i = 0; i < this.otcYears.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  year: this.otcYears[i],
                  maker: this.series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareVsLySelectedChannel,
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,
                  packagetype: this.shareVsLySelectedPackageType,              
                  diffLy: this.series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Year", "Maker", "Display", "Channel", "Category", "Region", "Package Type", "Diff.LY"]
          });
          break;
        default:
          var series = getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes });      
            for (var k = 0; k < series.length; k++)
            {
              for (var i = 0; i < weeks.length; i++) {
                data.push({
                  week: weeks[i],
                  maker: series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareVsLySelectedChannel,
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,
                  packagetype: this.shareVsLySelectedPackageType,              
                  diffLy: series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Week", "Maker/MAV", "Display", "Channel", "Category", "Region", "Package Type", "Diff.LY"]
          });
          break;
      }
      
    }
    this.showOptionDownload();
  }

  dropdownValueToNgSelectFormat(value) {
    const values = DropdownValueToNgSelectFormat(value);
    if (values?.length && this?.otcStartWeeks) {
      if (values.length < this.otcStartWeeks) {
        setTimeout(() => {
          this.otcStartWeeks = values.length
        }, 500);
      }
    }
    return values
  }

  onMovingAverageWindowSizeChange(value){
    this.movingAverageWindowSizes = value;
    this.handleMarketShareVsLy();
  }  

}
