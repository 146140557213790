import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import html2canvas from 'src/html2canvas';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';

@Component({
  selector: 'intage-otc-market-share-volume-ccbji',
  templateUrl: './otc-market-share-volume-ccbji.component.html',
  styleUrls: ['./otc-market-share-volume-ccbji.component.scss']
})
export class IntageOtcMarketShareVolumeCCBJIComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intageMonth: any;
  @Input() intage: any;
  @Input() users: any;
  //FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  weeks: any;
  categories: any;
  data: any;
  categoryColors: any;
  makerLogo: any;
  summaryRegions: any;
  summaryPackageTypes: any;
  otcDisplayChannel: string;
  otcSelectedRegion: any;
  otcSelectedPackageType: any;
  marketShareData2: any;
  groupMarketShareData2: any[];
  populated: boolean;
  aggregateBy: any;
  chartId = "otc-market-volume-share-by-category";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }  

  ngOnChanges() {
    if(!this.populated){
      this.otcSelectedRegion = ['CCBJI Territory'];
      this.otcSelectedPackageType = ['All'];
      this.otcDisplayChannel = '1';
      this.categoryColors = IntageHelper.CategoryColors();
      this.makerLogo = IntageHelper.MakerLogos();
      this.populated = true;
    }
    if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
      this.categories = IntageHelper.Categories();
      this.summaryRegions = IntageHelper.Regions();
      this.summaryPackageTypes = IntageHelper.PackageType();
      this.handleMarketSize();
      this.handleMarketShare2();
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["otcSelectedRegion"] = [...this.otcSelectedRegion];
      window["otcSelectedPackageType"] = [...this.otcSelectedPackageType];
    } else {
      this.otcSelectedRegion = window["otcSelectedRegion"];
      this.otcSelectedPackageType = window["otcSelectedPackageType"];
    }
  }
  onRemoveFilterOTCRegion($event) {
    if(this.otcSelectedRegion.length == 0){
      this.otcSelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
      this.onOtcSelectedRegionChange()
    }
    if ($event.value === 'CCBJI Territory') {
      this.otcSelectedRegion = [];
      return;
    }
  }
  onAddFilterOTCRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.otcSelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.otcSelectedRegion.filter(x=> x!='CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.otcSelectedRegion = ['CCBJI Territory'];
    }else{
      this.otcSelectedRegion = this.otcSelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  }
  onRemoveFilterOTCPackageType($event) {
    if(this.otcSelectedPackageType.length == 0){
      this.otcSelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onOtcSelectedPackageTypeChange()
    }
    if ($event.value === 'All') {
      this.otcSelectedPackageType = [];
      return;
    }
  }
  onAddFilterOTCPackageType($event) {
    if ($event === 'All') {
      this.otcSelectedPackageType = ['All'];
      return;
    }
    if(this.otcSelectedPackageType.filter(x=> x!='All').length == (this.summaryPackageTypes.length-1)){
      this.otcSelectedPackageType = ['All'];
    }else{
      this.otcSelectedPackageType = this.otcSelectedPackageType.filter(e => e !== 'All');;
    }
  }

  onOTCMarketsizeAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketSize();
    this.handleMarketShare2();
  }

  handleMarketSize(){
    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData(); 
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();       
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();  
        break;
      default:
        weeks = [this.selectedWeek];
        this.handleIntageDataMarketSize(weeks);
        break;
    }
  }

  public async firstcheckIntageAllData()
  {
    var intage = await IndexDbHelper.Get('IntageFlashAllData'); 
    if (intage != null)
    {
      this.intage = intage;
      this.handleIntageDataMarketSize(window["weeks"]);
    }
    else
    {
      this.checkIntageFlashAllData();
    }
  }

  public async checkIntageFlashAllData()
  {
    setTimeout(async () => {
      var intage = await IndexDbHelper.Get('IntageFlashAllData');  
      if (intage != null)
      {
        this.intage = intage;
        this.handleIntageDataMarketSize(window["weeks"]);
      }
      else
      {
        this.checkIntageFlashAllData();
      }
    }, 200);
  }

  public handleIntageDataMarketSize(weeks: any)
  {
    var temp = this.intage.filter(x=> weeks.includes(x.week)); 
    let totalChannel = {
      channel: 'OTC Total',
      categories: [{
        category: 'Total',
        vsLyVolume:'',
        imp: 100.0,
        tyVolume: 0,
        lyVolume: 0
      }]
    };
    let smddChannel = {
      channel: 'SMDD',
      categories: [{
        category: 'Total',
        vsLyVolume:'',
        imp: 100.0,
        tyVolume: 0,
        lyVolume: 0
      }]
    };
    this.data = [totalChannel, smddChannel];
    for(var i=0;i<temp.length;i++){
      let row = temp[i];
      if(!this.otcSelectedRegion.includes('CCBJI Territory') && !this.otcSelectedRegion.includes(row.region)){
        continue;
      }
      if(!this.otcSelectedPackageType.includes('All') && !this.otcSelectedPackageType.includes(row.packageType)){
        continue;
      }
      let channel = this.data.find(x=> x.channel == row.channel);
      if(channel == null){
        channel = {
          channel: row.channel,
          categories: [{
            category: 'Total',
            vsLyVolume:'',
            imp: 100.0,
            tyVolume: 0,
            lyVolume: 0    
          }]
        }
        this.data.push(channel);
      }
      let category = channel.categories.find(x=> x.category == row.category);
      if(category == null){
        category = {
          category: row.category,
          vsLyVolume:'',
          imp: 0,
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume),  
        }
        channel.categories.push(category);
      }else{
        category.tyVolume+=parseFloat(row.tyVolume);
        category.lyVolume+=parseFloat(row.lyVolume);
      }
      let totalCategory = totalChannel.categories.find(x=> x.category == row.category);
      if(totalCategory == null){
        totalCategory = {
          category: row.category,
          vsLyVolume:'',
          imp: 0,
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume),  
        }
        totalChannel.categories.push(totalCategory);
      }else{
        totalCategory.tyVolume+=parseFloat(row.tyVolume);
        totalCategory.lyVolume+=parseFloat(row.lyVolume);
      }
      channel.categories[0].tyVolume+=parseFloat(row.tyVolume);
      channel.categories[0].lyVolume+=parseFloat(row.lyVolume);
      totalChannel.categories[0].tyVolume+=parseFloat(row.tyVolume);
      totalChannel.categories[0].lyVolume+=parseFloat(row.lyVolume);
      if(row.channel == 'Drug' || row.channel == 'SM' || row.channel == 'Discounter'){
        let smddCategory = smddChannel.categories.find(x=> x.category == row.category);
        if(smddCategory == null){
          smddCategory = {
            category: row.category,
            vsLyVolume:'',
            imp: 0,
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume),    
          }
          smddChannel.categories.push(smddCategory);
        }else{
          smddCategory.tyVolume+=parseFloat(row.tyVolume);
          smddCategory.lyVolume+=parseFloat(row.lyVolume);
        }
        smddChannel.categories[0].tyVolume+=parseFloat(row.tyVolume);
        smddChannel.categories[0].lyVolume+=parseFloat(row.lyVolume);
      }
    }
    for(var i=0;i<this.data.length;i++){
      for(var j=0;j<this.data[i].categories.length;j++){
        let category = this.data[i].categories[j];
        if (category.lyVolume == 0)
        {
          category.vsLyVolume = '-';
        }
        else if (category.tyVolume == 0)
        {
          category.vsLyVolume = '-100.0';
        }
        else
        {
          category.vsLyVolume = ((category.tyVolume/category.lyVolume) * 100 - 100).toFixed(1);
        }
        category.imp = this.data[i].categories[0].tyVolume == 0 ? "-" : (category.tyVolume / this.data[i].categories[0].tyVolume * 100).toFixed(1);
      }
    }

    if(this.otcDisplayChannel == '1'){
      this.data = this.data.filter(x=> x.channel != 'SM' && x.channel!= 'Drug' && x.channel!= 'Discounter');
    }
    else{
      this.data = this.data.filter(x=> x.channel != 'SMDD');
    }
  }

  handleMarketShare2(){
    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllDataMarketShare();
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllDataMarketShare();       
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllDataMarketShare(); 
        break;
      default:
        weeks = [this.selectedWeek];
        this.handleIntageDataMarketShare(weeks);
        break;
    }
  }

  public async firstcheckIntageAllDataMarketShare()
  {
    var intage = await IndexDbHelper.Get('IntageFlashAllData'); 
    if (intage != null)
    {
      this.intage = intage;
      this.handleIntageDataMarketShare(window["weeks"]);
    }
    else
    {
      this.checkIntageFlashAllDataMarketShare();
    }
  }

  public async checkIntageFlashAllDataMarketShare()
  {
    setTimeout(async () => {
      var intage = await IndexDbHelper.Get('IntageFlashAllData');  
      if (intage != null)
      {
        this.intage = intage;
        this.handleIntageDataMarketShare(window["weeks"]);
      }
      else
      {
        this.checkIntageFlashAllDataMarketShare();
      }
    }, 200);
  }

  public handleIntageDataMarketShare(weeks: any)
  {     
    var data = this.intage.filter(x=> weeks.includes(x.week));  
    this.marketShareData2 = [];
    let totals = [{
      channel: 'OTC Total',
      category: 'Total',
      tyVolume:0,
      lyVolume:0
    }];
    this.groupMarketShareData2 = [
      { index: 1, maker: "KIRIN", logo: "", channels:[]},
      { index: 2, maker: "ASAHI", logo: "", channels:[]},
      { index: 3, maker: "ITOEN", logo: "", channels:[]},
      { index: 4, maker: "OTHER", logo: "", channels:[]}
    ];
    for(var i=0;i<data.length;i++){
      let row = data[i];
      if(!this.otcSelectedRegion.includes('CCBJI Territory') && !this.otcSelectedRegion.includes(row.region)){
        continue;
      }

      let maker = this.marketShareData2.find(x=> x.maker == row.maker);
      let total = totals.find(x=> x.channel == row.channel && x.category == row.category);
      if(total == null){
        total = {
          channel: row.channel,
          category: row.category,
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume)
        }
        totals.push(total);
      }else{
        total.tyVolume +=parseFloat(row.tyVolume);
        total.lyVolume +=parseFloat(row.lyVolume);
      }
      let categoryTotal = totals.find(x=> x.channel == row.channel && x.category == 'Total');
      if(categoryTotal == null){
        categoryTotal = {
          channel: row.channel,
          category: 'Total',
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume)
        }
        totals.push(categoryTotal);
      }else{
        categoryTotal.tyVolume +=parseFloat(row.tyVolume);
        categoryTotal.lyVolume +=parseFloat(row.lyVolume);
      }
      let channelTotal = totals.find(x=> x.channel == 'OTC Total' && x.category == row.category);
      if(channelTotal == null){
        channelTotal = {
          channel: 'OTC Total',
          category: row.category,
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume)
        }
        totals.push(channelTotal);
      }else{
        channelTotal.tyVolume +=parseFloat(row.tyVolume);
        channelTotal.lyVolume +=parseFloat(row.lyVolume);
      }

      totals[0].tyVolume +=parseFloat(row.tyVolume);
      totals[0].lyVolume +=parseFloat(row.lyVolume);

      if(!this.otcSelectedPackageType.includes('All') && !this.otcSelectedPackageType.includes(row.packageType)){
        continue;
      }
      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              share: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: ''
            }]
          },
          {
            channel: 'SMDD',
            categories: [{
              category : 'Total',
              share: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: ''
            }]
          }]
        };
        this.marketShareData2.push(maker);
      }

      let channel = maker.channels.find(x=> x.channel == row.channel);
      if(channel == null){
        channel = {
          channel: row.channel,
          categories: [
            {
              category: 'Total',
              share: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: ''
            }
          ]
        };
        maker.channels.push(channel);
      }

      let category = channel.categories.find(x=> x.category == row.category);
      if(category == null){
        category = {
          category: row.category,
          share: '',
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume),
          totalTyVolume: 0,
          totalLyVolume: 0,
          vsLyVolume: ''
        };
        channel.categories.push(category);
      }
      else{
        category.tyVolume += parseFloat(row.tyVolume);
        category.lyVolume += parseFloat(row.lyVolume);
      }
      let makerCategory = maker.channels[0].categories.find(x=> x.category == row.category);
      if(makerCategory == null){
        makerCategory = {
          category: row.category,
          share: '',
          tyVolume: parseFloat(row.tyVolume),
          lyVolume: parseFloat(row.lyVolume),
          totalTyVolume: 0,
          totalLyVolume: 0,
          vsLyVolume: ''
        };
        maker.channels[0].categories.push(makerCategory);
      }
      else{
        makerCategory.tyVolume += parseFloat(row.tyVolume);
        makerCategory.lyVolume += parseFloat(row.lyVolume);
      }
      channel.categories[0].tyVolume+= parseFloat(row.tyVolume);
      channel.categories[0].lyVolume+= parseFloat(row.lyVolume);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
      if(row.channel == 'SM' || row.channel == 'Drug' || row.channel == 'Discounter'){
        maker.channels[1].categories[0].tyVolume+= parseFloat(row.tyVolume);
        maker.channels[1].categories[0].lyVolume+= parseFloat(row.lyVolume);
        let smddCategory = maker.channels[1].categories.find(x=> x.category == row.category);
        if(smddCategory == null){
          smddCategory = {
            category: row.category,
            share: '',
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume),
            totalTyVolume: 0,
            totalLyVolume: 0,
            vsLyVolume: ''
          };
          maker.channels[1].categories.push(smddCategory);
        }
        else{
          smddCategory.tyVolume += parseFloat(row.tyVolume);
          smddCategory.lyVolume += parseFloat(row.lyVolume);
        }
      }
    }

    for(var i=0;i<this.marketShareData2.length;i++){
      let maker = this.marketShareData2[i];
      for(var j=0;j<maker.channels.length;j++){
        for(var k=0;k<maker.channels[j].categories.length;k++){
          let category = maker.channels[j].categories[k];
          if(maker.channels[j].channel != 'SMDD'){
            let total = totals.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;

            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);
          }
          else{
            let totalList = totals.filter(x=> x.category == category.category && (x.channel == 'SM' || x.channel == 'Drug' || x.channel == 'Discounter'));
            for(var t=0;t<totalList.length;t++){
              let total = totalList[t];
              category.totalTyVolume += total.tyVolume;
              category.totalLyVolume += total.lyVolume;
            }
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);
          }
        }
      }
    }

    this.marketShareData2.sort((a, b) => a.index > b.index ? 1 : -1);
    for(var i=0;i<this.marketShareData2.length;i++){
      let maker = this.marketShareData2[i];
      if(this.otcDisplayChannel == '1'){
        maker.channels = maker.channels.filter(x=> x.channel != 'SM' && x.channel!= 'Drug' && x.channel!= 'Discounter');
      }
      else{
        maker.channels = maker.channels.filter(x=> x.channel != 'SMDD');
      }
    }
    //group maker
    this.marketShareData2.forEach(e => {
      var maker = this.groupMarketShareData2.find(x=>x.maker == e.maker);
      if(maker != null){
        maker.channels = e.channels.slice(0,1);
        maker.logo = e.logo;
      }
    });
    //filter maker in group
    this.marketShareData2 = this.marketShareData2.filter( x=> !this.groupMarketShareData2.some(y=>y.maker == x.maker));
    let t1= this;
    setTimeout(() => {
      t1.ready.emit(true);
    });
  }

  public FilterGroupOther(groups: any[]){
    if(!groups){
      return [];
    }
    return groups.filter( x=> x.maker != "OTHER");
  }
  public getCategoryColor(category: string){
    return IntageHelper.GetCategoryColor(category);
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }
  public getShareOfCategory(categories: any, category: string){
    return IntageHelper.GetShareVolumeOfCategory(categories, category);
  }

  public getVsLyVolumeOfCategory(categories: any, category: string){
    return IntageHelper.GetVsLyVolumeOfCategory(categories, category);
  }

  public getVsLyVolumeOfCategoryForMarketSize(categories: any, category: string){
    return IntageHelper.GetVsLyVolumeOfCategoryForMarketSize(categories, category);
  }

  public getImpOfCategory(categories: any, category: string){
    return IntageHelper.GetImpOfCategory(categories, category);
  }
  public onOtcDisplayChannelChange(channelType: string){
    this.otcDisplayChannel = channelType;
    this.handleMarketSize();
    this.handleMarketShare2();
  }

  public onOtcSelectedRegionChange(){
    if(this.otcSelectedRegion.length==0){
      this.otcSelectedRegion = ['CCBJI Territory'];
    }
    this.handleMarketSize();
    this.handleMarketShare2();
  }

  public onOtcSelectedPackageTypeChange(){
    if(this.otcSelectedPackageType.length==0){
      this.otcSelectedPackageType = ['All'];
    }
    this.handleMarketSize();
    this.handleMarketShare2();
  }

  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  public exportOTCMarketSizeCCBJI(isImage: boolean) {
    const fileName = 'OTC Market Volume Share by Category';
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      for (var i = 0; i < this.data.length; i++) {
        let channel = this.data[i];
        for (var j = 0; j < channel.categories.length; j++) {
          let category = channel.categories[j];
          data.push({
            week: this.selectedWeek,
            aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
            display: this.otcDisplayChannel == "1" ? "Channel Level 1" : "Channel Level 2",
            region : this.otcSelectedRegion,
            packagetype: this.otcSelectedPackageType,
            maker: 'Market Size',
            channel: channel.channel,
            category: category.category,
            ty: category.tyVolume,
            ly: category.lyVolume,
            share: '',
            totalTyVolume: '',
            totalLyVolume: '',
            imp: category.imp,
            vsLyVolume: category.vsLyVolume,
            diffLy: '',
          });
        }
      }

      for(var i=0;i<this.marketShareData2.length;i++){
        // FPT-HoiLT2 [20221130][SENSE][1203454213752038]Intage OTC Flash CSV Corrections SMDD Start
        let maker = Object.assign({}, this.marketShareData2[i]);
        if(this.otcDisplayChannel == '1'){
          maker.channels = maker.channels.filter(x=> x.channel != 'SM' && x.channel!= 'Drug' && x.channel!= 'Discounter');
        }
        else{
          maker.channels = maker.channels.filter(x=> x.channel != 'SMDD');
        }
        // FPT-HoiLT2 [20221130][SENSE][1203454213752038]Intage OTC Flash CSV Corrections SMDD End
        for(var j=0;j<maker.channels.length;j++){
          let channel = maker.channels[j];
          for(var k=0;k<channel.categories.length;k++){
            let category = channel.categories[k];
            data.push({
              week: this.selectedWeek,
              aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
              display: this.otcDisplayChannel == "1" ? "Channel Level 1" : "Channel Level 2",
              region : this.otcSelectedRegion,
              packagetype: this.otcSelectedPackageType,
              maker: maker.maker,
              channel: channel.channel,
              category: category.category,
              ty: category.tyVolume,
              ly: category.lyVolume,
              share: category.shareVolume,
              totalTyVolume: category.totalTyVolume,
              totalLyVolume: category.totalLyVolume,
              imp: '',
              vsLyVolume: '',
              diffLy: category.vsLyVolume,
            });
          }
        }
      }

      for(var i=0;i<this.groupMarketShareData2.length;i++){
        // FPT-HoiLT2 [20221130][SENSE][1203454213752038]Intage OTC Flash CSV Corrections SMDD Start
        let maker = Object.assign({}, this.groupMarketShareData2[i]);
        if(this.otcDisplayChannel == '1'){
          maker.channels = maker.channels.filter(x=> x.channel != 'SM' && x.channel!= 'Drug' && x.channel!= 'Discounter');
        }
        else{
          maker.channels = maker.channels.filter(x=> x.channel != 'SMDD');
        }
        // FPT-HoiLT2 [20221130][SENSE][1203454213752038]Intage OTC Flash CSV Corrections SMDD End
        for(var j=0;j<maker.channels.length;j++){
          let channel = maker.channels[j];
          for(var k=0;k<channel.categories.length;k++){
            let category = channel.categories[k];
            data.push({
              week: this.selectedWeek,
              aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
              display: this.otcDisplayChannel == "1" ? "Channel Level 1" : "Channel Level 2",
              region : this.otcSelectedRegion,
              packagetype: this.otcSelectedPackageType,
              maker: maker.maker,
              channel: channel.channel,
              category: category.category,
              ty: category.tyVolume,
              ly: category.lyVolume,
              share: category.shareVolume,
              totalTyVolume: category.totalTyVolume,
              totalLyVolume: category.totalLyVolume,
              imp: '',
              vsLyVolume: '',
              diffLy: category.vsLyVolume,
            });
          }
        }
      }
      new AngularCsv(data, fileName, {
        showLabels: true,
        headers: ["Week", "Aggregate By", "Display", "Region", "Package Type", "Maker", "Channel", "Category", "TY_Volume", "LY_Volume", "Share_Volume", "Total_TY_Volume", "Total_LY_Volume", "IMP", "% vs LY_Volume", "Diff_LY_Volume"],
      });
    }
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-market-size-volume-ccbji');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  @ViewChild('pdf_print_area_5', { static: false }) pdf_print_area_5: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_5.nativeElement)
  }

}
