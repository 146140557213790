const transactionName = 'Report';
const transactionMode = 'readwrite';

export class IndexDbHelper {
    static async Set(key: string, value: any, expirationMonths: number = 2) {
        let db = window['db'] as IDBDatabase;

        if (db == null) {
            return;
        }

        let tx    = db.transaction(transactionName, transactionMode);
        let store = tx.objectStore(transactionName);
        const now = new Date();
        let expirationTime = null;

        if (expirationMonths > 0) {
            const expirationDate = new Date(now.getFullYear(), now.getMonth() + expirationMonths, now.getDate());
            expirationTime       = parseInt(expirationDate.toISOString().split('T')[0].replace(/-/g, ''));
            console.log(`Setting expiration time for key: ${key} to ${expirationDate.toISOString()}`);
        }

        tx    = db.transaction(transactionName, transactionMode);
        store = tx.objectStore(transactionName);

        await store.put({ id: key, data: value, expiration: expirationTime });
    }

    static async Get(key: string) {
        return new Promise(function (resolve) {
            let db = window['db'] as IDBDatabase;

            if (db == null) {
                return resolve(null);
            }

            const tx      = db.transaction(transactionName, transactionMode);
            const store   = tx.objectStore(transactionName);
            const request = store.get(key);

            request.onsuccess = (event) => {
                const result = request.result;

                if (result) {
                    if (['IntageVmDashboardWeeks', 'IntageDashboardWeeks'].includes(key)) {
                      return resolve(result.data);
                    }

                    if (!result.expiration) {
                        console.log(`No expiration field found for key: ${key}. Deleting...`);
                        store.delete(key);
                        return resolve(null);
                    }

                    const expiration     = result.expiration.toString() || '';
                    const expirationDate = new Date(expiration.slice(0, 4), expiration.slice(4, 6) - 1, expiration.slice(6, 8));
                    const today          = new Date();
                    today.setHours(0, 0, 0, 0);

                    if (expirationDate < today) {
                        console.log(`Data for key: ${key} has expired. Deleting...`);
                        store.delete(key);
                        return resolve(null);
                    }

                    return resolve(result.data);
                } else {
                    return resolve(null);
                }
            };

            request.onerror = (event) => {
                console.error(`Error retrieving data for key: ${key}`, (event.target as IDBRequest).error);
                return resolve(null);
            };
        });
    }

    static async DeleteOldData(dbNames: string[]) {
        return new Promise(function (resolve, reject) {
            let db = window['db'] as IDBDatabase;

            if (db == null) {
                return resolve(null);
            }

            const now          = new Date();
            const twoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, now.getDate());
            const cutoffDate   = parseInt(twoMonthsAgo.toISOString().split('T')[0].replace(/-/g, ''));

            const tx            = db.transaction(transactionName, transactionMode);
            const store         = tx.objectStore(transactionName);
            const cursorRequest = store.openCursor();

            cursorRequest.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest).result;

                if (cursor) {
                    const key     = cursor.key as string;
                    const isMatch = dbNames.some(dbName => key.startsWith(`${dbName}_`));

                    if (isMatch) {
                        let datePart: number | null = null;

                        if (key.includes('_')) {
                            const dateString = key.split('_')[1];

                            if (dateString.includes('-')) {
                                datePart = parseInt(dateString.replace(/-/g, ''));
                            } else if (/^\d{8}$/.test(dateString)) {
                                datePart = parseInt(dateString);
                            }
                        }

                        if (datePart !== null && datePart < cutoffDate) {
                            console.log(`Deleting: ${key}`);
                            cursor.delete();
                        }
                    }

                    cursor.continue();
                } else {
                    console.log('Clearing complete.');
                    resolve(null);
                }
            };

            cursorRequest.onerror = (event) => {
                const request = event.target as IDBRequest;
                console.error('Cursor error:', request.error);
                reject(request.error);
            };
        });
    }
}