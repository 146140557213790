import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { authorize } from 'src/app/helpers/AuthorizePage';
import { SalesDateService } from 'src/app/components/elements/sales-date-filter/sales-date-filter.service';
import { SalesDateHelper } from 'src/app/components/elements/sales-date-filter/helper';
import { RequestService } from 'src/app/services/request.service';
import { PocHelper } from './poc.helper';
import { PocQueries } from 'src/app/model/constants';

@Component({
  selector: 'app-faceshare',
  templateUrl: './poc.component.html',
  styleUrls: ['./poc.component.scss']
})

export class PocComponent implements OnInit, OnDestroy {
  private loadingSubscription: Subscription;
  private salesDateSubscription: Subscription;

  public isLoading: boolean      = false;
  public lstAllOptionsData : any = [];
  public lstOptionAggregatedDateType: any = null;
  public lstOptionSelectedSalesDate: any = null;

  isSalesYMReady: boolean;
  isCategoryFilterReady: boolean;
  isAccountFilterReady: boolean;
  isCategoryReady: boolean;
  isAccountReady: boolean;

  constructor(private router: Router,
              private requestService: RequestService,
              private salesDateService: SalesDateService) {}

  async ngOnInit() {
    if (!authorize()) {
      this.router.navigateByUrl('unauthorized');
    } else {
      this.loadingSubscription = PocHelper.isLoading().subscribe((loading: boolean) => {
        this.isLoading = loading;
      });

      this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          this.requestService.sendRequest(PocQueries.filter, {}, true).then((res) => {
            if (res) {
              const salesYM    = JSON.parse(res)?.SALES_YM || [];
                            const lstSalesYM = salesYM.filter((elt: string) => PocHelper.compareDates(elt, date[0]))
                                                      .map((elt: string) => ({ name: elt, value: elt })) || [];

              SalesDateHelper.setPocSalesDate(lstSalesYM);
              this.isSalesYMReady = true;
              this.checkPageReadyForUse();
            }
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.salesDateService.setTraxSalesDate([]);

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  public onDateTypeSaveItemsChange(e: any) {
    const {selectedDateType, selectedSalesDate} = e;

    if (selectedDateType) {
      this.lstOptionAggregatedDateType = selectedDateType;
    }

    if (selectedSalesDate) {
      this.lstOptionSelectedSalesDate = [...selectedSalesDate];
    }
  }

  public categoryFilterReady(ready: boolean) {
    this.isCategoryFilterReady = ready;
    this.checkPageReadyForUse();
  }

  public accountFilterReady(ready: boolean) {
    this.isAccountFilterReady = ready;
    this.checkPageReadyForUse();
  }

  public categoryReady(ready: boolean){
    this.isCategoryReady = ready;
  }

  public accountReady(ready: boolean) {
    this.isAccountReady = ready;
  }

  private checkPageReadyForUse() {
    if (this.isCategoryFilterReady && this.isAccountFilterReady && this.isSalesYMReady) {
      PocHelper.setSalesDate(['MTD']);
    }
  }
}