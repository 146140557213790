import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import html2canvas from 'src/html2canvas';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
import lodashClonedeep from 'lodash.clonedeep';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
const regions = ['CCBJI', 'East Japan', 'Kanto', 'Tokyo', 'Central Japan', 'Kinki', 'Chugoku-Shikoku', 'Kyushu'];
@Component({
  selector: 'otc-market-share-vm-by-region',
  templateUrl: './otc-market-share-vm-by-region.component.html',
  styleUrls: ['./otc-market-share-vm-by-region.component.scss']
})
export class IntageOtcMarketShareVMByRegionComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @Input() users: any;
  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  categories: any;
  categoryColors: any;
  smddChanelOrder: any;
  marketShareByRegionData: any;
  region_list: any;
  selectedRegionChannel: any;
  selectedMaker: any;
  selectedMakerLogo: string;
  makerLogo: any;
  makers: any;
  summaryPackageTypes: any;
  filterByRegionSelectedPackageType: any;
  populated: boolean;
  total_maker: any;
  regions: any;
  aggregateBy: any;
  chartId = "vm-market-value-share-by-district";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges() {
    if(!this.populated){
      this.filterByRegionSelectedPackageType = ['TOTAL'];
      this.categoryColors = IntageHelper.CategoryColorsOTCByRegionVM();
      this.smddChanelOrder = IntageHelper.SmddChannels();
      this.selectedRegionChannel = ['All'];
      this.makerLogo = IntageHelper.MakerLogos();
      this.selectedMaker = [this.makerLogo[0].maker];
      this.selectedMakerLogo = this.makerLogo[0].logo;
      this.populated = true;
    }
    if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
      this.categories = IntageHelper.categoriesFilter();
      this.makers = IntageHelper.makers();
      this.summaryPackageTypes = IntageHelper.packageType();
      this.handleMarketShareByRegion();
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["selectedMaker"] = [...this.selectedMaker];
      window["filterByRegionSelectedPackageType"] = [...this.filterByRegionSelectedPackageType];
    } else {
      this.selectedMaker = window["selectedMaker"];
      this.filterByRegionSelectedPackageType = window["filterByRegionSelectedPackageType"];
    }
  }
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }
  onChangeMakerOTC() {
    if(this.selectedMaker.length == 0){
      this.selectedMaker = ['COCA-COLA'];
      this.SaveOptionValueTemp(false);
      this.onFilterByMakerChange()
    }
  }
  
  onRemoveFilterSharePackageType($event) {
    if(this.filterByRegionSelectedPackageType.length == 0){
      this.filterByRegionSelectedPackageType = ['TOTAL'];
      this.SaveOptionValueTemp(false);
      this.onFilterByRegionPackageTypeChange()
    }
    if ($event.value === 'TOTAL') {
      this.filterByRegionSelectedPackageType = [];
      return;
    }
  }
  onAddFilterSharePackageType($event) {
    if ($event === 'TOTAL') {
      this.filterByRegionSelectedPackageType = ['TOTAL'];
      return;
    }
    if(this.filterByRegionSelectedPackageType.filter(x=> x!='TOTAL').length == (this.summaryPackageTypes.length-1)){
      this.filterByRegionSelectedPackageType = ['TOTAL'];
    }else{
      this.filterByRegionSelectedPackageType = this.filterByRegionSelectedPackageType.filter(e => e !== 'TOTAL');;
    }
  } 

  public onAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
    this.handleMarketShareByRegion();
  }

  quarterTransform(month: string): any {
    let monthOnly = month.substring(5,7);
    switch(monthOnly) {
      case '01':
      case '02':
      case '03':
        return month.substring(0,5)+'01';
      case '04':
      case '05':
      case '06':
        return month.substring(0,5)+'02';
      case '07':
      case '08':
      case '09':
        return month.substring(0,5)+'03';
      case '10':
      case '11':
      case '12':
        return month.substring(0,5)+'04';
    } 
  }

  handleMarketShareByRegion(){
    let intage = lodashClonedeep(this.intage);
    let data = [];
    let data_total_market = [];
    switch (this.aggregateBy) {
      case 'mtd':
        let month = this.intageMonth.find(y => y.week == this.selectedWeek).month;
        data = intage.filter(x => x.maker == 'COCA-COLA' && (this.intageMonth.find(y => y.week == x.week).month == month) && x.week <= this.selectedWeek);  
        data_total_market = intage.filter(x => (this.intageMonth.find(y => y.week == x.week).month == month) && x.week <= this.selectedWeek);
        break;
      case 'qtd':
        let quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        data = intage.filter(x => x.maker == 'COCA-COLA' && (this.intageMonth.find(y => y.week == x.week).quarter == quarter) && x.week <= this.selectedWeek);
        data_total_market = intage.filter(x => (this.intageMonth.find(y => y.week == x.week).quarter == quarter) && x.week <= this.selectedWeek);
        break;
      case 'ytd':
        let year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        data = intage.filter(x => x.maker == 'COCA-COLA' && (this.intageMonth.find(y => y.week == x.week).year == year) && x.week <= this.selectedWeek);
        data_total_market = intage.filter(x => parseInt(x.week.substring(0, 4)) == parseInt(this.selectedWeek.substring(0, 4)) && parseInt(x.week.replace('-','').replace('-','')) <= parseInt(this.selectedWeek.replace('-','').replace('-','')));
        break;
      default:
        data = intage.filter(x => x.week == this.selectedWeek && x.maker == 'COCA-COLA');
        data_total_market = intage.filter(x => x.week == this.selectedWeek);
        break;
    }

    // data.map(x => {
    //   if (x.category == 'その他')
    //   {
    //     x.category = 'Other';
    //   }
    //   if (x.maker == 'KO')
    //   {
    //     x.maker = 'COCA-COLA';
    //   }
    //   if (x.botter_sci == 'Kansai')
    //   {
    //     x.botter_sci = 'Kinki';
    //   }
    // });

    // data_total_market.map(x => {
    //   if (x.category == 'その他')
    //   {
    //     x.category = 'Other';
    //   }
    //   if (x.maker == 'KO')
    //   {
    //     x.maker = 'COCA-COLA';
    //   }
    //   if (x.botter_sci == 'Kansai')
    //   {
    //     x.botter_sci = 'Kinki';
    //   }
    // });

    // this.categories.map(x => {
    //   if (x.category == 'その他')
    //   {
    //     x.category = 'Other';
    //   }
    // })

    // this.makers.map(x => {
    //   if (x.maker == 'KO')
    //   {
    //     x.maker = 'COCA-COLA';
    //   }
    // })

    // this.region_list = [];
    // data.forEach(x => {
    //   if (!this.region_list.includes(x.botter_sci))
    //   {
    //     this.region_list.push(x.botter_sci);
    //   }
    // });

    this.region_list = regions;


    // this.region_list.sort((a,b) => {
    //   if (a > b)
    //   {
    //     return 1;
    //   }
    //   return -1;
    // });

    this.marketShareByRegionData = [];

    var total_market_category = [];
    this.categories.forEach(c => {
      this.region_list.forEach(region => {
        var ty_value = 0;
        var ly_value = 0;
        var category_region_filter = data_total_market.filter(x => x.category == c && x.botter_sci == region); 
        category_region_filter.forEach(x => {
          ty_value += parseFloat(x.tyvalue);
          ly_value += parseFloat(x.lyvalue);
        });
        if (category_region_filter.length == 0)
        {
          ty_value = null;
          ly_value = null;
        }
        total_market_category.push(
          {category: c, region: region, tyvalue: ty_value, lyvalue: ly_value}
        );
      })
    })

    var total_market_total_category = [];
    this.region_list.forEach(region => {
      var ty_value = 0;
      var ly_value = 0;
      var region_filter = total_market_category.filter(x => x.region == region);
      region_filter.forEach(x => {
        ty_value += x.tyvalue;
        ly_value += x.lyvalue;
      });
      if (region_filter.length == 0)
      {
        ty_value = null;
        ly_value = null;
      }
      total_market_total_category.push(
        {region: region, tyvalue: ty_value, lyvalue: ly_value}
      )
    })

    this.region_list.forEach(region => {
      var ty_value = 0;
      var ly_value = 0;
      var region_filter = data.filter(x => x.botter_sci == region);
      region_filter.forEach(x => {
        ty_value += parseFloat(x.tyvalue);
        ly_value += parseFloat(x.lyvalue);
      });
      if (region_filter.length == 0)
      {
        ty_value = null;
        ly_value = null;
      }
      let total_market_total_category_tyvalue = total_market_total_category.filter(x => x.region == region)[0]?.tyvalue;
      let total_market_total_category_lyvalue = total_market_total_category.filter(x => x.region == region)[0]?.lyvalue;
      let share_ty_norounding =  (ty_value == null || total_market_total_category_tyvalue == 0) ? null : (ty_value / total_market_total_category_tyvalue * 100);
      let share_ly_norounding = (ly_value == null || total_market_total_category_lyvalue == 0) ? null : (ly_value / total_market_total_category_lyvalue * 100);
      let share_ty =  (ty_value == null || total_market_total_category_tyvalue == 0) ? null : (ty_value / total_market_total_category_tyvalue * 100).toFixed(1);
      let diff_ly = (share_ty_norounding == null || share_ly_norounding == null) ? null : (share_ty_norounding - share_ly_norounding).toFixed(1);
      this.marketShareByRegionData.push({week: this.selectedWeek, botter_sci: region, maker: 'COCA-COLA', category: 'Total', tyvalue: ty_value, lyvalue: ly_value, share: share_ty, diff_ly: diff_ly});
    })
    
    this.categories.forEach(c => {
      this.region_list.forEach(region => {
        if (c.toLowerCase() != 'total')
        {
          var region_category_tyvalue = 0;
          var region_category_lyvalue = 0;
          var region_category_list = data.filter(x => x.category == c && x.botter_sci == region);
          region_category_list.forEach(x => {
            region_category_tyvalue += parseFloat(x.tyvalue);
            region_category_lyvalue += parseFloat(x.lyvalue);
          });
          if (region_category_list.length == 0)
          {
            region_category_tyvalue = null;
            region_category_lyvalue = null;
          }

          var tyvalue_marketsize = total_market_category.filter(x => x.category == c && x.region == region)[0]?.tyvalue;
          var lyvalue_marketsize = total_market_category.filter(x => x.category == c && x.region == region)[0]?.lyvalue;
          let share_ty_norounding =  (region_category_tyvalue == null || tyvalue_marketsize == 0) ? null : (region_category_tyvalue / parseFloat(tyvalue_marketsize) * 100);
          let share_ly_norounding = (region_category_lyvalue == null || lyvalue_marketsize == 0) ? null : (region_category_lyvalue / parseFloat(lyvalue_marketsize) * 100);
          let share_ty =  (region_category_tyvalue == null || tyvalue_marketsize == 0) ? null : (region_category_tyvalue / parseFloat(tyvalue_marketsize) * 100).toFixed(1);
          let diff_ly = (share_ty_norounding == null || share_ly_norounding == null) ? null : (share_ty_norounding - share_ly_norounding).toFixed(1);
          this.marketShareByRegionData.push({week: this.selectedWeek, botter_sci: region, maker: 'COCA-COLA', category: c, tyvalue: region_category_tyvalue,
          lyvalue: region_category_lyvalue, share: share_ty, diff_ly: diff_ly});
        }
      })
    })

    this.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    let t= this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }
  public getCategoryColor(category: string){
    return IntageHelper.GetCategoryColor(category);
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }

  public onFilterByRegionChannelChange(){
    if(this.selectedRegionChannel.length == 0){
      this.selectedRegionChannel = ['All'];
    }
    this.handleMarketShareByRegion();
  }

  public onFilterByRegionPackageTypeChange(){
    if(this.filterByRegionSelectedPackageType.length == 0){
      this.filterByRegionSelectedPackageType = ['TOTAL'];
    }
    this.handleMarketShareByRegion();
  }

  public onFilterByMakerChange(){
    if(this.selectedMaker.length==0){
      this.selectedMaker = ['COCA-COLA'];
    }
    this.selectedMakerLogo = this.makerLogo.find(x=> x.maker == this.selectedMaker)?.logo ?? '';
    this.handleMarketShareByRegion();
  }

  public getShareOfCategory(marketShareByRegionData: any, region: string, category: string){
    if (marketShareByRegionData.filter(x => x.botter_sci == region && x.category == category)[0]?.share == null)
    {
      return '-';
    }
    return marketShareByRegionData.filter(x => x.botter_sci == region && x.category == category)[0]?.share;
  }

  public getVsLyOfCategory(marketShareByRegionData: any, region: string, category: string){
    if (marketShareByRegionData.filter(x => x.botter_sci == region && x.category == category)[0]?.diff_ly == null)
    {
      return '-';
    }
    return marketShareByRegionData.filter(x => x.botter_sci == region && x.category == category)[0]?.diff_ly;
  }

  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  public exportOTCMarketValueShareByRegion(isImage: boolean) {
    const fileName = 'VM Market Value Share by District';
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      this.marketShareByRegionData.forEach(x => {
        data.push({
          week: x.week,
          aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
          maker: this.selectedMaker[0],
          packagetype: this.filterByRegionSelectedPackageType[0],                  
          region: x.botter_sci,
          category: x.category,
          ty: x.tyvalue,
          ly: x.lyvalue,
          share: x.share,
          diffly: x.diff_ly
        });
      })

      new AngularCsv(data, fileName, {
        showLabels: true,
        headers: ["Week", "Aggregate By", "Maker", "Package Type", "Region", "Category", "TY_Value", "LY_Value", "Share", "Diff.LY"]
      });
    }
    // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-market-value-region');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  @ViewChild('pdf_print_area_2', { static: false }) pdf_print_area_2: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_2.nativeElement)
  }

}

