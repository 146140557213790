<div class="row">
    <div class="col-lg-12 col-md-12">
        <div #scroll class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 *ngIf="shareDisplayType=='value'">Value Share</h3>                  
                <h3 *ngIf="shareDisplayType=='volume'">Volume Share</h3>
                <!-- <div class="csv-download" [style]="'position: relative'">
                    <i class="bx bxs-arrow-to-bottom" (click)="showOptionDownload()"></i>
                    <div [id]="'download-option-share-timeline'" style="display: none;" class="apexcharts-menu apexcharts-menu-open">
                        <div class="apexcharts-menu-item" title="Download PNG" (click)="exportValueShareTimeline(true)">Download PNG</div>
                        <div class="apexcharts-menu-item" title="Download CSV" (click)="exportValueShareTimeline(false)">Download CSV</div>
                    </div>
                </div> -->
            </div>

            <div class="filter-section no-title mb-4">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onValueShareAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Weekly'}, {value: 'mtd', name: 'Monthly'}, {value: 'qtd', name: 'Quarterly'}, {value: 'ytd', name: 'Yearly'}]"
                    [model]="aggregateBy"
                    (change)="onValueShareAggregateChange($event)">
                </single-dropdown>

                <moving-average-dropdown title="Display"
                    [items]="[{value: 4, name: '4-Week Moving Average'}]"
                    (change)="onMovingAverageWindowSizeChange($event)"
                    [(model)]="movingAverageWindowSizes"
                    >
                </moving-average-dropdown>

            </div>

            <div class="filter-radio title-makers">
                <div class="items">
                    <div class="item" *ngFor="let maker of skipAllValue(marketShareMakers)">
                        <input class="form-check-input" (change)="onShareMakersCheckedChanged(maker.maker)" type="checkbox" value="{{maker.maker}}" [checked]="maker.checked" [disabled]="marketShareHaveSingleSerie && maker.maker === marketShareSingleSerie">
                        <label class="form-check-label" >{{maker.maker}}</label>
                    </div>
                </div>            
            </div>

            <!-- FPT-ThinhLD4: [SENSE] INTAGE page Region Dropdownbox -->
            <div class="filter-section even-wrapper">

                <!-- <div class="ng-section summary-section native">
                    <b>Display</b>
                    <select class="form-control filter-by-category form-basic" (change)="onShareDisplayTypeChange($event.target.value)">
                        <option value="value">Value Share</option>
                        <option value="volume">Volume Share</option>
                    </select>
                </div> -->
                <single-dropdown title="Display"
                    [items]="[{value: 'value', name: 'Value Share'}, {value: 'volume', name: 'Volume Share'}]"
                    [model]="shareDisplayType"
                    (change)="onShareDisplayTypeChange($event)" >
                </single-dropdown>

                <div class="ng-section summary-section">
                    <b>Channel</b>
                    <ng-select #ngFilterChannelTimeline class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareTimelineSelectedChannel[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterTimelineChannel($event)"
                    (change)="onRemoveFilterTimelineChannel($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilteTimelineChannel($event)"
                    [clearable]="shareTimelineSelectedChannel.length > 1">
                        <ng-option *ngFor="let item of otcChannels" [value]="item.channel">
                            <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item.channel) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item.channel}}</ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary"
                                (click)="SaveOptionValueTemp(false);ngFilterChannelTimeline.close();onShareTimeLineChannelChange()">Aggregate</button>
                            </ng-template>                                
                            <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Category</b>
                    <ng-select #ngFilterCategoryTimeline class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareTimelineSelectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterShareCategory($event)"
                    (change)="onRemoveFilterShareCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterShareCategory($event)"
                    [clearable]="shareTimelineSelectedCategory.length > 1">
                        <ng-option *ngFor="let item of categoriesFilter" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{getCategoryDisplayName(item)}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterCategoryTimeline.close();onShareTimeLineCategoryChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterTimelineRegion class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareTimelineSelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterTimelineRegion($event)"
                    (change)="onRemoveFilterTimelineRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterSTimelineRegion($event)"
                    [clearable]="shareTimelineSelectedRegion.length > 1">
                        <ng-option *ngFor="let item of summaryRegions" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterTimelineRegion.close();onShareTimeLineRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Package Type</b>
                    <ng-select #ngFilterPackageTypeTimeline class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareTimelineSelectedPackageType[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterTimelinePackageType($event)"
                    (change)="onRemoveFilterTimelinePackageType($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterTimelinePackageType($event)"
                    [clearable]="shareTimelineSelectedPackageType.length > 1">
                        <ng-option *ngFor="let item of summaryPackageTypes" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterPackageTypeTimeline.close();onShareTimeLinePackageTypeChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div #screen class="card-body">
                <div id="intage-market-share-chart"></div>
                <ng-container *ngIf="marketShareHaveSingleSerie">
                    <div class="single-series-container {{aggregateBy ?? 'wtd'}} movingAverageWindowSizes-{{movingAverageWindowSizes.length}}">
                        <span class="red-dot" [ngStyle]="{backgroundColor: marketShareSingleSerieColor}"></span>
                        <span class="single-series">{{marketShareSingleSerie}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!marketShareHaveData">
                    <div class="no-data-container">
                        <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                    </div>
                </ng-container>
            </div>        
            <div class="flex-two-ends">
                <single-dropdown title="Start week"
                    [items]="dropdownValueToNgSelectFormat(otcWeeks)"
                    (change)="onSelectedStartWeekChanged($event)"
                    [(model)]="otcStartWeeks">
                </single-dropdown>
            </div>

            <div class="flex-two-ends mt-4 hide-pdf-print">
                <!-- <comment-button [title1]="shareDisplayType=='value' ? 'Value Share' : 'Volume Share'"
                [chartId]="chartId"
                [chartRef]="marketShareChart"
                [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', movingAverageWindowSizes, marketShareMakers, shareDisplayType, shareTimelineSelectedChannel, shareTimelineSelectedCategory, shareTimelineSelectedRegion, shareTimelineSelectedPackageType, otcWeeks, otcStartWeeks}}]"></comment-button> -->

                <download-button>
                    <button ngbDropdownItem (click)="exportValueShareTimeline(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportValueShareTimeline(false)">Download CSV</button>
                </download-button>
            </div>

            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>