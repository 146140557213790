// sales-date.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesDateService {
  private seDateInJobClearCache = new BehaviorSubject<string[]>([]);
  private traxDateInJobClearCache = new BehaviorSubject<string[]>([]);

  constructor(private http: HttpClient) {}

  // SE Report
  setSESalesDate(value: string[]) {
    return this.seDateInJobClearCache.next(value);
  }

  getSESalesDate() {
    return this.seDateInJobClearCache.asObservable();
  }

  getSEDateInJobClearCache() {
    if (this.seDateInJobClearCache.value.length > 0) {
      return;
    }

    const queryUrl = environment.hygraphGetSEJobUpdate;
    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    }

    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
      response => {
        let latestDate = '';
        const res = JSON.parse(response);

        if (res.data && res.data[environment.clearCacheSEJobStatus] != null && res.data[environment.clearCacheSEJobStatus].length > 0) {
          latestDate = this.formatDate(res.data[environment.clearCacheSEJobStatus][0].date);
        } else {
          latestDate = this.getYesterdaySaleDate();
        }

        localStorage.setItem('se.dateinjobclearcache', latestDate);
        this.setSESalesDate([latestDate]);
      },
      error => {
        console.log(error);
      }
    );
  }

  // Trax Report
  setTraxSalesDate(value: string[]) {
    return this.traxDateInJobClearCache.next(value);
  }

  getTraxSalesDate() {
    return this.traxDateInJobClearCache.asObservable();
  }

  getTraxDateInJobClearCache() {
    if (this.traxDateInJobClearCache.value.length > 0) {
      return;
    }

    const queryUrl = environment.hygraphGetTraxJobUpdate;
    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    }

    this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
        response => {
          let latestDate = '';
          const res = JSON.parse(response);

          if (res.data && res.data[environment.clearCacheTraxJobStatus] != null && res.data[environment.clearCacheTraxJobStatus].length > 0) {
            latestDate = this.formatDate(res.data[environment.clearCacheTraxJobStatus][0].date);
          } else {
            latestDate = this.getYesterdaySaleDate();
          }

          localStorage.setItem('trax.dateinjobclearcache', latestDate);
          this.setTraxSalesDate([latestDate]);
        },
        error => {
          console.log(error);
        }
      );
  }

  // Helper
  formatDate(latestDate: string) {
    if (!latestDate || !latestDate.length) {
      return '';
    }

    const year  = latestDate.substring(0, 4) || '';
    const month = latestDate.substring(4, 6) || '';
    const day   = latestDate.substring(6, 8) || '';

    return `${year}-${month}-${day}`;
  }

  getYesterdaySaleDate() {
    const yesterday = new Date();

    yesterday.setDate(yesterday.getDate() - 1);
    const year  = yesterday.getFullYear();
    const month = (yesterday.getMonth() + 1).toString().padStart(2, '0');
    const day   = yesterday.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
