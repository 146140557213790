<div class="row">
    <div class="col-lg-12 col-md-12">
        <div #scroll class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Share Diff vs LY</h3>
                <!-- <div class="csv-download" [style]="'position: relative'">
                    <i class="bx bxs-arrow-to-bottom" (click)="showOptionDownload()"></i>
                    <div [id]="'download-option-share-vs-ly'" style="display: none;" class="apexcharts-menu apexcharts-menu-open">
                        <div class="apexcharts-menu-item" title="Download PNG" (click)="exportSharevsLY(true)">Download PNG</div>
                        <div class="apexcharts-menu-item" title="Download CSV" (click)="exportSharevsLY(false)">Download CSV</div>
                    </div>
                </div> -->
            </div>

            <div class="filter-section no-title mb-4">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onSharevslyAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Weekly'}, {value: 'mtd', name: 'Monthly'}, {value: 'qtd', name: 'Quarterly'}, {value: 'ytd', name: 'Yearly'}]"
                    [model]="aggregateBy"
                    (change)="onSharevslyAggregateChange($event)">
                </single-dropdown>

                <moving-average-dropdown title="Display"
                    [items]="[{value: 4, name: '4-Week Moving Average'}]"
                    (change)="onMovingAverageWindowSizeChange($event)"
                    [(model)]="movingAverageWindowSizes"
                    >
                </moving-average-dropdown>

            </div>

            <div class="filter-radio title-makers">
                <div class="items">
                    <div class="item" *ngFor="let maker of filterMakers(marketShareVsLyMakers); index as i;">
                        <input class="form-check-input" (change)="onShareVsLyMakersCheckedChanged(maker.maker)" type="checkbox" value="{{maker.maker}}" [checked]="maker.checked" [disabled]="marketShareVsLyHaveSingleSerie && maker.maker === marketShareVsLySingleSerie">
                        <label class="form-check-label" >{{maker.maker}}</label>
                    </div>
                </div>            
            </div>

            <div class="filter-section even-wrapper">

                <!-- <div class="ng-section summary-section native">
                    <b>Display</b>
                    <select class="form-control filter-by-category form-basic" (change)="onShareVsLyDisplayTypeChange($event.target.value)">
                        <option value="value">Value Share</option>
                        <option value="volume">Volume Share</option>
                    </select>
                </div> -->
                <single-dropdown title="Display"
                    [items]="[{value: 'value', name: 'Value Share'}, {value: 'volume', name: 'Volume Share'}]"
                    [model]="shareVsLyDisplayType"
                    (change)="onShareVsLyDisplayTypeChange($event)" >
                </single-dropdown>

                <div class="ng-section summary-section">
                    <b>Channel</b>
                    <ng-select #ngFilterChannelLy class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsLySelectedChannel[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterLyChannel($event)"
                    (change)="onRemoveFilterLyChannel($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilteLyChannel($event)"
                    [clearable]="shareVsLySelectedChannel.length > 1">
                        <ng-option *ngFor="let item of otcChannels" [value]="item.channel">
                            <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item.channel) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item.channel}}</ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary"
                                (click)="SaveOptionValueTemp(false);ngFilterChannelLy.close();onShareVsLyChannelChange()">Aggregate</button>
                            </ng-template>                                
                            <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Category</b>
                    <ng-select #ngFilterCategoryLy class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsLySelectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilteLyCategory($event)"
                    (change)="onRemoveFilteLyCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterLyCategory($event)"
                    [clearable]="shareVsLySelectedCategory.length > 1">
                        <ng-option *ngFor="let item of categoriesFilter" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{getCategoryDisplayName(item)}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterCategoryLy.close();onShareVsLyCategoryChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterRegionLy class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsLySelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterLyRegion($event)"
                    (change)="onRemoveFilterLyRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterLyRegion($event)"
                    [clearable]="shareVsLySelectedRegion.length > 1">
                        <ng-option *ngFor="let item of summaryRegions" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterRegionLy.close();onShareVsLyRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Package Type</b>
                    <ng-select #ngFilterPackageTypeLy class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsLySelectedPackageType[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterLyPackageType($event)"
                    (change)="onRemoveFilterLyPackageType($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterLyPackageType($event)"
                    [clearable]="shareVsLySelectedPackageType.length > 1">
                        <ng-option *ngFor="let item of summaryPackageTypes" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterPackageTypeLy.close();onShareVsLyPackageTypeChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div #screen class="card-body">
                <div id="intage-market-share-vsLy-chart"></div>
                <ng-container *ngIf="marketShareVsLyHaveSingleSerie">
                    <div class="single-series-container {{aggregateBy ?? 'wtd'}} movingAverageWindowSizes-{{movingAverageWindowSizes.length}}">
                        <span class="red-dot" [ngStyle]="{backgroundColor: marketShareVsLySingleSerieColor}"></span>
                        <span class="single-series">{{marketShareVsLySingleSerie}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!marketShareVsLyHaveData">
                    <div class="no-data-container">
                        <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                    </div>
                </ng-container>
            </div>
            <div class="flex-two-ends">
                <!-- <div class="ng-section summary-section week-start native">
                    <b>Start week</b>
                    <select class="form-control" (change)="onSelectedStartWeekChanged($event.target.value)">
                        <option *ngFor="let item of otcWeeks; index as i;" [value]="this.otcWeeks.indexOf(this.selectedWeek) - i + 1" [hidden]="this.otcWeeks.indexOf(this.selectedWeek) - i + 1 < 1" [selected]="this.otcWeeks.length - this.otcWeeks.indexOf(this.selectedWeek) + otcStartWeeks - 1 === otcWeeks.length - i">{{item}}</option>
                    </select>
                </div> -->
                <single-dropdown title="Start Week"
                    [items]="dropdownValueToNgSelectFormat(otcWeeks)"
                    (change)="onSelectedStartWeekChanged($event)"
                    [(model)]="otcStartWeeks">
                </single-dropdown>                
            </div>

            <div class="flex-two-ends mt-4 hide-pdf-print">
                <!-- <comment-button title1="Share Diff vs LY"
                [chartId]="chartId"
                [chartRef]="marketShareVsLyChart"
                [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', movingAverageWindowSizes, marketShareVsLyMakers, shareVsLyDisplayType, shareVsLySelectedChannel, shareVsLySelectedCategory, shareVsLySelectedRegion, shareVsLySelectedPackageType, otcWeeks, otcStartWeeks}}]"></comment-button> -->

                <download-button>
                    <button ngbDropdownItem (click)="exportSharevsLY(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportSharevsLY(false)">Download CSV</button>
                </download-button>
            </div>

            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>